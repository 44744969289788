import React, { useState, useEffect } from 'react';
import { listUsers } from '../../apiservice/apiService.js';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  Grid
} from '@mui/material';
import AddUserDialog from './AddUserDialog';
import UserPermissions from './UserPermissions';
import UpdateUserDialog from './UpdateUserDialog';
import ResetPasswordDialog from './ResetPasswordDialog';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VpnKeyIcon from '@mui/icons-material/VpnKey';

const permissionsList = ['Assets', 'Alarms', 'Events', 'Admin Panel'];

const AdminPanel = () => {
  const [users, setUsers] = useState([]);
  const [isAddUserDialogOpen, setIsAddUserDialogOpen] = useState(false);
  const [isUpdateUserDialogOpen, setIsUpdateUserDialogOpen] = useState(false);
  const [isResetPasswordDialogOpen, setIsResetPasswordDialogOpen] = useState(false);
  const [isRemoveUserDialogOpen, setIsRemoveUserDialogOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const fetchedUsers = await listUsers();
      setUsers(fetchedUsers);
    } catch (error) {
      console.error('Failed to fetch users:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDialogOpen = (type, user = null) => {
    setSelectedUser(user);
    if (type === 'add') setIsAddUserDialogOpen(true);
    else if (type === 'update') setIsUpdateUserDialogOpen(true);
    else if (type === 'resetPassword') setIsResetPasswordDialogOpen(true);
    else if (type === 'remove') setIsRemoveUserDialogOpen(true);
  };

  const handleDialogClose = (type) => {
    if (type === 'add') setIsAddUserDialogOpen(false);
    else if (type === 'update') setIsUpdateUserDialogOpen(false);
    else if (type === 'resetPassword') setIsResetPasswordDialogOpen(false);
    else if (type === 'remove') setIsRemoveUserDialogOpen(false);
  };

  const handleUserUpdate = (updatedUser) => {
    console.log('Updating user:', updatedUser);
    fetchUsers();
  };

  const handleResetPassword = (userId) => {
    console.log('Resetting password for user:', userId);
  };

  const removeUser = (userId) => {
    console.log('Removing user:', userId);
    fetchUsers();
  };

  return (
    <Box p={3} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Grid item xs={12}>
        <Button variant='contained' onClick={() => handleDialogOpen('add')}>
          Add User
        </Button>
      </Grid>
      <AddUserDialog open={isAddUserDialogOpen} onClose={() => handleDialogClose('add')} onUserAdded={fetchUsers} />
      {loading
        ? (
          <p>Loading...</p>
          )
        : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell>Username</TableCell>
                <TableCell>Permissions</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => (
                <TableRow key={user.email}>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.username}</TableCell>
                  <TableCell>
                    <UserPermissions user={user} permissionsList={permissionsList} onPermissionChange={fetchUsers} />
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleDialogOpen('update', user)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDialogOpen('resetPassword', user)}>
                      <VpnKeyIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDialogOpen('remove', user)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          )}
      <UpdateUserDialog open={isUpdateUserDialogOpen} onClose={() => handleDialogClose('update')} user={selectedUser} onUserUpdate={handleUserUpdate} />
      <ResetPasswordDialog open={isResetPasswordDialogOpen} onClose={() => handleDialogClose('resetPassword')} onResetPassword={() => handleResetPassword(selectedUser?.id)} />
      <Dialog open={isRemoveUserDialogOpen} onClose={() => handleDialogClose('remove')}>
        <DialogTitle>Confirm User Removal</DialogTitle>
        <DialogActions>
          <Button onClick={() => handleDialogClose('remove')}>Cancel</Button>
          <Button onClick={() => removeUser(selectedUser?.id)}>Remove</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AdminPanel;

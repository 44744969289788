/**
 * Converts a JavaScript Date object to DICOM timestamp format.
 * @param {Date} date - The date to be converted.
 * @returns {number} - The DICOM timestamp.
 * @throws Will throw an error if the input is not a Date object.
 */
export function createDcmTimestamp (date) {
  // Validate if the input is a Date object
  if (!(date instanceof Date)) {
    throw new Error('Input must be a Date object');
  }

  // Years between 1601 and 1970
  const yearsBetween1601And1970 = 369;
  // Leap years between 1601 and 1970 (excluding 1700 which is not a leap year)
  const leapYearsBetween1601And1970 = 89;
  // Total days from 1601 to 1970
  const days = (yearsBetween1601And1970 * 365) + leapYearsBetween1601And1970;
  // 100ns units in a day
  const unitsInADay = 24 * 60 * 60 * 10000000;
  // Offset in 100ns units from 1601 to 1970
  const offsetIn100nsUnits = days * unitsInADay;

  // Convert JavaScript date object (milliseconds from Unix Epoch) to 100ns units from Unix Epoch
  const unixTimestampIn100nsUnits = date.getTime() * 10000;

  // Add the offset to convert 100ns units from Unix Epoch to 100ns units from 1601
  const dcmTimestamp = unixTimestampIn100nsUnits + offsetIn100nsUnits;

  return dcmTimestamp;
}

export const stringToColor = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xFF;
    color += ('00' + value.toString(16)).substr(-2);
  }
  return color;
};

export const calculateStartTime = (dateRange) => {
  const timeBack = {
    '1h': 60 * 60 * 1000,
    '1d': 24 * 60 * 60 * 1000,
    '1w': 7 * 24 * 60 * 60 * 1000,
    '1m': 30 * 24 * 60 * 60 * 1000,
    '3m': 90 * 24 * 60 * 60 * 1000
  };
  return (new Date(new Date() - timeBack[dateRange])).getTime();
};

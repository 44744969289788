import React from 'react';
import PropTypes from 'prop-types';
import { Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const AddChartButton = ({ onButtonClick, style }) => {
  return (
    <Fab
      color='primary'
      aria-label='add'
      style={style}
      onClick={onButtonClick}
    >
      <AddIcon />
    </Fab>
  );
};

AddChartButton.propTypes = {
  onButtonClick: PropTypes.func,
  style: PropTypes.object
};

AddChartButton.defaultProps = {
  onButtonClick: () => {},
  style: { position: 'fixed', bottom: '16px', right: '16px' }
};

export default AddChartButton;

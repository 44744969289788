import React, { useMemo } from 'react';
import {
  ComposedChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Bar,
  Area,
  Scatter,
  Brush // Import Brush
} from 'recharts';
import { stringToColor } from '../helpers';

const formatDateWithDate = (date) => {
  if (!(date instanceof Date)) {
    return date;
  }
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  return `${year}-${month}-${day} \n${hours}:${minutes}`;
};

const Chart = React.memo(({ data, chartConfig }) => {
  const convertedData = useMemo(() => {
    const tempData = {};

    if (!data || !Array.isArray(data) || data.length === 0) {
      return [];
    }
    const chartType = chartConfig.samples[0]?.chartType;
    data.forEach(record => {
      const name = record?.Sample;
      let ts = new Date(record.ts);
      ts = formatDateWithDate(ts);
      const val = record.val;
      if (!tempData[ts]) {
        tempData[ts] = { ts };
      }
      tempData[ts][name] = val;
      tempData[ts][`${name}_chartType`] = chartType;
    });

    return Object.values(tempData).sort((a, b) => new Date(a.ts) - new Date(b.ts)); // Ensure sorting works with full datetime
  }, [data, chartConfig]);

  if (convertedData.length === 0) {
    return <p>No data available</p>;
  }

  let min = 0;
  let max = 100;

  chartConfig.samples.forEach(sample => {
    if (sample.SampleLowerLimit < min) {
      min = sample.SampleLowerLimit;
    }
    if (sample.SampleUpperLimit > max) {
      max = sample.SampleUpperLimit;
    }
  });

  return (
    <>
      <ResponsiveContainer
        width='100%'
        height='100%'
        debounce={1}
      >
        <ComposedChart
          data={convertedData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5
          }}
        >
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis
            dataKey='ts'
            height={60}
            tick={{
              angle: -20,
              textAnchor: 'end',
              fontSize: '10px',
              fontFamily: 'Arial'
            }}
          />
          <YAxis type='number' domain={[min, max]} />
          <Tooltip />
          <Legend />
          {chartConfig.samples.map((sample, index) => {
            if (sample.chartType === 'line') {
              return (
                <Line
                  key={index}
                  type='natural'
                  dataKey={sample.name}
                  activeDot={{ r: 3 }}
                  strokeWidth={1}
                  dot={false}
                  connectNulls
                  stroke={stringToColor(sample.name)}
                />
              );
            }
            if (sample.chartType === 'bar') {
              return (
                <Bar
                  key={index}
                  type='natural'
                  dataKey={sample.name}
                  activeDot={{ r: 3 }}
                  strokeWidth={1}
                  dot={false}
                  connectNulls
                  fill={stringToColor(sample.name)}
                />
              );
            }
            if (sample.chartType === 'area') {
              return (
                <Area
                  key={index}
                  type='natural'
                  dataKey={sample.name}
                  activeDot={{ r: 3 }}
                  strokeWidth={1}
                  dot={false}
                  connectNulls
                  fill={stringToColor(sample.name)}
                />
              );
            }
            if (sample.chartType === 'scatter') {
              return (
                <Scatter
                  key={index}
                  type='natural'
                  dataKey={sample.name}
                  activeDot={{ r: 3 }}
                  strokeWidth={1}
                  dot={false}
                  connectNulls
                  fill={stringToColor(sample.name)}
                />
              );
            }
            return null;
          })}
          {chartConfig.samples.map((sample, index) => {
            return sample.showBrush && <Brush key={index} dataKey='ts' height={30} stroke='#8884d8' className='react-resizable-handle remove' />;
          })}
        </ComposedChart>
      </ResponsiveContainer>
    </>
  );
});

export default Chart;

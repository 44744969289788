// AssetForm.js
import React, { useState } from 'react';
import { Grid, FormControl, InputLabel, Select, MenuItem, OutlinedInput, TextField, Button } from '@mui/material';
import { useStore } from '../../store/store';
import { useSnackbar } from 'notistack';

const AssetForm = ({ onCreateAsset }) => {
  const samplePoints = useStore(state => state.getSamplePoints());
  const [selectedSamples, setSelectedSamples] = useState([]);
  const [assetName, setAssetName] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  const handleSampleChange = (event) => {
    setSelectedSamples(event.target.value);
  };

  const handleAssetNameChange = (event) => {
    setAssetName(event.target.value);
  };

  const handleCreateAsset = async () => {
    if (selectedSamples.length > 0 && assetName) {
      const selectedSampleObjects = samplePoints.filter(sp => selectedSamples.includes(sp.SampleName));
      onCreateAsset(assetName, selectedSampleObjects);
      setSelectedSamples([]);
      setAssetName('');
      enqueueSnackbar('Asset created successfully', { variant: 'success' });
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <FormControl fullWidth variant='outlined'>
          <InputLabel id='sample-names-label'>Sample Names</InputLabel>
          <Select
            labelId='sample-names-label'
            label='Sample Names'
            multiple
            value={selectedSamples}
            onChange={handleSampleChange}
            input={<OutlinedInput label='Sample Names' />}
            renderValue={(selected) => selected.join(', ')}
          >
            {samplePoints.map((samplePoint, index) => (
              <MenuItem key={index} value={samplePoint.SampleName}>
                {samplePoint.SampleName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label='Asset Name'
          fullWidth
          variant='outlined'
          value={assetName}
          onChange={handleAssetNameChange}
        />
      </Grid>
      <Grid item xs={12}>
        <Button variant='contained' onClick={handleCreateAsset} disabled={!assetName || selectedSamples.length === 0}>
          Create Asset
        </Button>
      </Grid>
    </Grid>
  );
};

export default AssetForm;

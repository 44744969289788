import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { UpdateUserDetails } from '../apiservice/apiService';

const safeJsonParse = (json) => {
  try {
    return JSON.parse(json) || [];
  } catch (e) {
    console.error('Error parsing JSON:', e);
    return [];
  }
};

const safeJsonStringify = (value) => {
  try {
    return JSON.stringify(value);
  } catch (e) {
    console.error('Error stringifying JSON:', e);
    return '[]';
  }
};

const useLayoutsStore = create(persist(
  (set, get) => ({
    chartData: null,

    layouts: '[]',
    setLayouts: async newLayouts => {
      const safeLayouts = safeJsonStringify(newLayouts);
      set({ layouts: safeLayouts });
      await UpdateUserDetails({ dashboardLayout: safeLayouts });
      console.log('Layouts updated successfully');
    },
    getLayouts: () => safeJsonParse(get().layouts)
  }),
  {
    name: 'layouts-store',
    getStorage: () => window.localStorage
  }
));

export default useLayoutsStore;

import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const DateRangeSelector = ({ dateRange, handleRangeChange }) => {
  return (
    <FormControl fullWidth variant='outlined'>
      <InputLabel id='date-range-label'>Date Range</InputLabel>
      <Select
        id='date-range'
        label='Date Range'
        labelId='date-range-label'
        value={dateRange}
        onChange={handleRangeChange}
      >
        <MenuItem value='1h'>Last 1 hour</MenuItem>
        <MenuItem value='1d'>Last 1 day</MenuItem>
        <MenuItem value='1w'>Last 1 week</MenuItem>
        <MenuItem value='1m'>Last 1 month</MenuItem>
        <MenuItem value='3m'>Last 3 months</MenuItem>
      </Select>
    </FormControl>
  );
};

export default DateRangeSelector;

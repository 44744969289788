import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { readAssets, createAsset, updateAsset, deleteAsset } from '../apiservice/apiService';

const useNotificationStore = create(persist(
  (set, get) => ({
    assets: [],
    fetchAssets: async () => {
      const assetData = await readAssets();
      set({ assets: assetData?.results });
    },
    addAsset: async (newAssetData) => {
      const result = await createAsset(newAssetData);
      set((state) => ({ assets: [...state.assets, result.asset] }));
    },
    updateAsset: async (assetId, updatedData) => {
      const updatedAsset = await updateAsset(assetId, updatedData);
      set((state) => ({
        assets: state.assets.map((asset) => (asset.id === assetId ? updatedAsset : asset))
      }));
    },
    removeAsset: async (timestamp, Name) => {
      await deleteAsset(timestamp, Name);
      set((state) => ({
        assets: state.assets.filter((asset) => asset.timestamp !== timestamp)
      }));
    }
  }),
  {
    name: 'assets-store',
    getStorage: () => window.localStorage
  }
));

export default useNotificationStore;

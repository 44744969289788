import React from 'react';
import { Box, Typography, Paper, Divider } from '@mui/material';

const DeviceSpecifications = ({ sample }) => {
  const isSimulated = sample.SimulatorData !== undefined;

  return (
    <Paper elevation={3} style={{ padding: '16px', marginTop: '16px' }}>
      <Typography variant='h6' gutterBottom>
        Sample Specifications: {sample.SampleName}
      </Typography>
      <Divider />
      <Box marginTop={2}>
        <Typography><b>Name:</b> {sample.SampleName}</Typography>
        <Typography><b>Data Type:</b> {sample.SampleDataType}</Typography>
        <Typography><b>Description:</b> {sample.SampleDescription}</Typography>
        <Typography><b>Unit:</b> {sample.SampleUnit}</Typography>
        {sample.SamplesSaved && <Typography><b>Samples Saved:</b> {sample.SamplesSaved}</Typography>}
        {isSimulated && (
          <>
            <Typography color='secondary'>This is a simulated sample.</Typography>
            <Typography><b>Function:</b> {sample.SimulatorData.SimulatorFunction}</Typography>
            <Typography><b>Initial Value:</b> {sample.SimulatorData.SimulatorInitValue}</Typography>
            <Typography><b>Increment Value:</b> {sample.SimulatorData.SimulatorIncValue}</Typography>
            <Typography><b>Sample Interval:</b> {sample.SimulatorData.SimulatorSampleInterval} seconds</Typography>
          </>
        )}
        {sample.S7Var && (
          <>
            <Typography color='primary'>This is a real sample point.</Typography>
            <Typography><b>PLC Variable:</b> {sample.S7Var.S7PLCVar}</Typography>
            <Typography><b>Sample Interval:</b> {sample.S7Var.S7SampleInterval} seconds</Typography>
          </>
        )}
      </Box>
    </Paper>
  );
};

export default DeviceSpecifications;

import React from 'react';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';

export const DeviceDropDown = ({ samplePoints, handleDeviceClick, selectedDevice }) => {
  return (
    <FormControl fullWidth>
      <InputLabel id='device-select-label'>Select Device</InputLabel>
      <Select
        labelId='device-select-label'
        id='device-select'
        value={selectedDevice}
        label='Select Device'
        onChange={(event) => handleDeviceClick(event.target.value)}
      >
        {samplePoints.map((samplePoint) => (
          <MenuItem key={samplePoint.SampleName} value={samplePoint.SampleName}>
            {samplePoint.SampleName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

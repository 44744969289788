import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Tabs, Tab, Button, Box, IconButton, Drawer, List, ListItem, ListItemText, useTheme, useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';

const Navigation = ({ handleChange }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const tabs = [
    { label: 'About Us', link: '/about' },
    { label: 'Product', link: '/product' },
    { label: 'Contact', link: '/contact' }
  ];

  const handleTabChange = (event, newValue) => {
    handleChange(newValue);
    navigate(newValue);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const getCurrentTabValue = () => {
    if (location.pathname === '/') {
      return false;
    }
    const currentTab = tabs.find(tab => tab.link === location.pathname);
    return currentTab ? currentTab.link : false;
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant='h6' sx={{ my: 2 }}>
        Asenco IoT Platform
      </Typography>
      <List>
        {tabs.map((tab, index) => (
          <ListItem button key={tab.label} component={NavLink} to={tab.link} selected={location.pathname === tab.link}>
            <ListItemText primary={tab.label} />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position='static' sx={{ background: '#eef7ff' }}>
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          {isMobile ? (
            <>
              <IconButton
                color='primary'
                aria-label='open drawer'
                edge='start'
                onClick={handleDrawerToggle}
                sx={{ mr: 2 }}
              >
                <MenuIcon />
              </IconButton>
              <Drawer
                style={{ color: '#ff8989' }}
                variant='temporary'
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                  keepMounted: true // Better open performance on mobile.
                }}
                sx={{
                  display: { xs: 'block', sm: 'none' },
                  '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 }
                }}
              >
                {drawer}
              </Drawer>
            </>
          ) : (
            <Typography
              variant='h6'
              component={NavLink}
              to='/'
              sx={{ flexGrow: 1, color: '#333', textDecoration: 'none', fontWeight: 'bold' }}
            >
              Asenco IoT Platform
            </Typography>
          )}
          {isMobile
            ? null
            : (
              <Tabs
                value={getCurrentTabValue()}
                onChange={handleTabChange}
                indicatorColor='primary'
                textColor='inherit'
                aria-label='navigation tabs'
                sx={{ '.MuiTabs-indicator': { backgroundColor: '#0056b3' } }}
              >
                {tabs.map((tab, index) => (
                  <Tab
                    key={tab.label}
                    label={tab.label}
                    value={tab.link}
                    component={NavLink}
                    to={tab.link}
                    sx={{ color: '#555', '&.active': { color: '#0056b3', textDecoration: 'none' } }}
                  />
                ))}
              </Tabs>
              )}
          <Button
            color='primary'
            variant='contained'
            component={NavLink}
            to='/login'
            sx={{ ml: 2, backgroundColor: '#0056b3', '&:hover': { backgroundColor: '#003a70' } }}
          >
            Login
          </Button>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Navigation;

// useChartData.js
import { useState } from 'react';
import { fetchAggregatedData } from '../../apiservice/apiService';
import { calculateStartTime } from '../helpers';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
export const useChartData = (enqueueSnackbar, closeSnackbar) => {
  const [chartData, setChartData] = useState([]);
  const [chartConfig, setChartConfig] = useState({});

  const fetchAllChartData = async (deviceId, dateRange) => {
    const samples = [
      {
        name: deviceId,
        chartType: 'line',
        SampleLowerLimit: 0,
        SampleUpperLimit: 100
      }
    ];
    const requestData = {
      id: 'id',
      startTime: calculateStartTime(dateRange),
      samples
    };

    setChartConfig({ samples, dateRange, deviceId });

    try {
      const response = await fetchAggregatedData([requestData]);
      setChartData(response.id);
    } catch (error) {
      console.error('Error fetching chart data:', error);
      displayError(error);
    }
  };

  const displayError = (error) => {
    enqueueSnackbar(`Error fetching data: ${error.message}`, {
      variant: 'error',
      autoHideDuration: 6000,
      action: (key) => (
        <IconButton size='small' aria-label='close' color='inherit' onClick={() => closeSnackbar(key)}>
          <CloseIcon fontSize='small' />
        </IconButton>
      )
    });
  };

  return { chartData, chartConfig, fetchAllChartData };
};

import {
  AppBar, Toolbar, Typography, IconButton, Menu, MenuItem, useMediaQuery
} from '@mui/material';
import { styled } from '@mui/system';
import {
  Menu as MenuIcon,
  ExitToApp as LogoutIcon,
  AccountCircle as AccountCircleIcon,
  Fullscreen as FullscreenIcon,
  MoreVert as MoreVertIcon
} from '@mui/icons-material';
import { useCallback, useState } from 'react';
import { useStore } from '../../store/store';
import { Link, useNavigate } from 'react-router-dom';
import './logged-in.css';
import NotificationBell from './NotificationBell';

const StyledTypography = styled(Typography)({ flexGrow: 1 });

const Navbar = function ({ pathname }) {
  const notifications = [
    { id: 1, title: "Alarm 'High CO2 level' - cleared", message: "Severity: major, originator: Device 'Air Quality Sensor T1'", time: "week" },
    { id: 2, title: "New alarm 'High CO2 level'", message: "Severity: major, originator: Device 'Air Quality Sensor T1'", time: "week" },
    { id: 3, title: "New alarm 'High CO2 level'", message: "Severity: major, originator: Device 'Air Quality Sensor C1'", time: "week" },
    { id: 4, title: "New alarm 'High CO2 level'", message: "Severity: major, originator: Device 'Air Quality Sensor C1'", time: "week" },
    { id: 5, title: "Alarm 'High TVOC level' - cleared", message: "Severity: critical, originator: Device 'Air Quality Sensor C1'", time: "week" },
  ];

  const { toggleDrawer, isLoggedIn, setIsLoggedIn, email } = useStore(state => ({
    toggleDrawer: state.toggleDrawer,
    isLoggedIn: state.isLoggedIn,
    setIsLoggedIn: state.setIsLoggedIn,
    email: state.email
  }));

  const isMobile = useMediaQuery('(max-width:600px)');

  const [anchorEl, setAnchorEl] = useState(null); // State for menu anchor
  const navigate = useNavigate();

  const handleDrawerToggle = useCallback(() => toggleDrawer(), [toggleDrawer]);
  const handleLogout = () => {
    setIsLoggedIn(false);
    navigate('/');
  };

  const handleMoreClick = (event) => { // Open menu
    setAnchorEl(event.currentTarget);
  };

  const handleMoreClose = () => { // Close menu
    setAnchorEl(null);
  };

  const handleFullscreenClick = () => {
    // Check if the document is currently in fullscreen mode
    if (
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement
    ) {
      // Exit fullscreen mode
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      }
    } else {
      // Enter fullscreen mode
      const docEl = document.documentElement;
      if (docEl.requestFullscreen) {
        docEl.requestFullscreen();
      } else if (docEl.webkitRequestFullscreen) {
        docEl.webkitRequestFullscreen();
      } else if (docEl.mozRequestFullScreen) {
        docEl.mozRequestFullScreen();
      }
    }
  };

  return (
    <AppBar style={{ backgroundColor: '#415057', color: 'white' }}>
      <Toolbar>
        {isLoggedIn && (pathname === '/' || isMobile) && (
          <IconButton edge='start' color='inherit' aria-label='menu' onClick={handleDrawerToggle}>
            <MenuIcon color='black' />
          </IconButton>
        )}
        <StyledTypography variant='h6' />
        {isLoggedIn && (
          <>
            {/* Full Screen Button */}
            <IconButton color='inherit' onClick={handleFullscreenClick}>
              <FullscreenIcon />
            </IconButton>
            <NotificationBell notifications={notifications} />

            {/* Profile Button with Email */}
            <Link to='/profile'>
              <IconButton color='inherit' onClick={() => console.log('Profile clicked!')}>
                <AccountCircleIcon />
                <Typography variant='body1' style={{ marginLeft: 8, color: 'white' }}>
                  {email}
                </Typography>
              </IconButton>
            </Link>

            {/* More (Three Dots) Button and Menu */}
            <IconButton color='inherit' onClick={handleMoreClick}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMoreClose}
            >
              <MenuItem onClick={handleLogout}>
                <LogoutIcon style={{ marginRight: 8, color: 'black' }} />
                Logout
              </MenuItem>
              {/* Add more menu items as needed */}
            </Menu>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;

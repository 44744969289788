import React from 'react';
import AddChartButton from './AddChartButton';
import { useStore } from '../../store/store';
import { useSnackbar } from 'notistack';
import Layouts from './Layouts';
import ModalComponent from './ModalComponent';

const Dashboard = (props) => {
  const { openChartModal, userData } = useStore(state => ({
    isChartModalOpen: state.isChartModalOpen,
    openChartModal: state.openChartModal,
    userData: state.getUser()
  }));
  const { enqueueSnackbar } = useSnackbar();

  return (
    <>
      <Layouts {...props} />
      <AddChartButton onButtonClick={() => {
        if (!userData.secomeaJSON) {
          enqueueSnackbar('Please add your Secomea JSON file in the Profile section', { variant: 'error' });
          return;
        }
        openChartModal();
      }}
      />
      <ModalComponent />
    </>
  );
};

export default Dashboard;

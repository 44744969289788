import React, { useState, useCallback, useMemo } from 'react';
import { Box, Paper, CircularProgress, Grid } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useStore } from '../../store/store';
import DateRangeSelector from '../DateRangeSelector';
import Chart from '../dashboard/Chart';
import { DeviceDropDown } from './DeviceDropDown';
import { useChartData } from './useChartData';
import DeviceSpecifications from './DeviceSpecifications';

const Devices = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [dateRange, setDateRange] = useState('1h');
  const [deviceId, setDeviceId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const samplePoints = useStore(state => state.getSamplePoints());
  const { chartData, chartConfig, fetchAllChartData } = useChartData(enqueueSnackbar, closeSnackbar);

  const handleDeviceClick = useCallback(async (selectedDeviceId) => {
    if (!selectedDeviceId) return;
    setIsLoading(true);
    setDeviceId(selectedDeviceId);
    try {
      await fetchAllChartData(selectedDeviceId, dateRange);
    } catch (error) {
      enqueueSnackbar('Error fetching data', { variant: 'error' });
    } finally {
      setIsLoading(false);
    }
  }, [dateRange, fetchAllChartData, enqueueSnackbar]);

  const handleRangeChange = useCallback((e) => {
    const newDateRange = e.target.value;
    setDateRange(newDateRange);
    if (deviceId) handleDeviceClick(deviceId);
  }, [deviceId, handleDeviceClick]);

  const isChartDataAvailable = useMemo(() => chartData.length > 0 && typeof chartData === 'object', [chartData]);

  const selectedSample = useMemo(() => {
    return samplePoints.find(sp => sp.SampleName === deviceId);
  }, [samplePoints, deviceId]);

  return (
    <Box p={3}>
      <Paper style={{ padding: '10px', marginBottom: '10px', backgroundColor: '#f5f5f5' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            {/* Device Selection Dropdown */}
            <DeviceDropDown
              samplePoints={samplePoints}
              handleDeviceClick={handleDeviceClick}
              selectedDevice={deviceId}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            {/* Time Range Selection Dropdown */}
            <DateRangeSelector
              dateRange={dateRange}
              handleRangeChange={handleRangeChange}
            />
          </Grid>
        </Grid>
      </Paper>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          {/* Chart Section */}
          {isLoading && <CircularProgress />}
          {!isLoading && chartData.length === 0 && <p>Select a device to view data</p>}
          {!isLoading && chartData.length !== 0 && typeof chartData === 'string' && <p>No Data Found!</p>}
          {!isLoading && isChartDataAvailable && <Chart chartConfig={chartConfig} data={chartData} />}
        </Grid>

        <Grid item xs={12} md={6}>
          {/* Device Specifications Section */}
          {deviceId && <DeviceSpecifications sample={selectedSample} />}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Devices;

import React, { useState, useEffect } from 'react';
import { Grid, FormControl, InputLabel, Select, MenuItem, TextField } from '@mui/material';
import moment from 'moment';

const timeOptions = {
  '1min': { label: 'Last 1 Minute', duration: [1, 'minutes'] },
  '5min': { label: 'Last 5 Minutes', duration: [5, 'minutes'] },
  '30min': { label: 'Last 30 Minutes', duration: [30, 'minutes'] },
  '1h': { label: 'Last 1 Hour', duration: [1, 'hours'] },
  '12h': { label: 'Last 12 Hours', duration: [12, 'hours'] },
  '1d': { label: 'Last 24 Hours', duration: [1, 'days'] },
  custom: { label: 'Custom', duration: null }
};

const CustomDateRangePicker = ({ onDateChange }) => {
  const [selectedDateRange, setSelectedDateRange] = useState('5min');
  const [startDate, setStartDate] = useState(() => moment().subtract(5, 'minutes').format('YYYY-MM-DDTHH:mm'));
  const [endDate, setEndDate] = useState(() => moment().format('YYYY-MM-DDTHH:mm'));

  useEffect(() => {
    if (selectedDateRange === 'custom') return;
    const { duration } = timeOptions[selectedDateRange];
    const startMoment = moment().subtract(...duration);
    const start = startMoment.format('YYYY-MM-DDTHH:mm');
    const startTimestamp = startMoment.valueOf();

    const endMoment = moment();
    const end = endMoment.format('YYYY-MM-DDTHH:mm');
    const endTimestamp = endMoment.valueOf();

    setStartDate(start);
    setEndDate(end);
    if (startDate !== start || endDate !== end) {
      onDateChange({ startDate: startTimestamp, endDate: endTimestamp });
    }
  }, [selectedDateRange, startDate, endDate, onDateChange]);

  const handleDateRangeSelection = (event) => {
    const value = event.target.value;
    setSelectedDateRange(value);
  };

  const handleStartDateChange = (event) => {
    const newStartDate = event.target.value;
    setStartDate(newStartDate);
    onDateChange({ startDate: new Date(newStartDate).getTime(), endDate: new Date(endDate).getTime() });
    setSelectedDateRange('custom');
  };

  const handleEndDateChange = (event) => {
    const newEndDate = event.target.value;
    setEndDate(newEndDate);
    onDateChange({ startDate: new Date(startDate).getTime(), endDate: new Date(newEndDate).getTime() });
    setSelectedDateRange('custom');
  };

  return (
    <Grid
      container
      spacing={2}
      alignItems='center'
      justifyContent='flex-start'
      style={{ width: 'auto' }}
    >
      <Grid item>
        <FormControl size='small' sx={{ marginRight: 2 }}>
          <InputLabel>Date Range</InputLabel>
          <Select
            value={selectedDateRange}
            label='Date Range'
            onChange={handleDateRangeSelection}
          >
            {Object.entries(timeOptions).map(([key, { label }]) => (
              <MenuItem key={key} value={key}>{label}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item>
        <TextField
          id='start-date'
          label='Start Date'
          type='datetime-local'
          value={startDate}
          onChange={handleStartDateChange}
          InputLabelProps={{
            shrink: true
          }}
          sx={{ marginRight: 2 }}
        />
        <TextField
          id='end-date'
          label='End Date'
          type='datetime-local'
          value={endDate}
          onChange={handleEndDateChange}
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>
    </Grid>
  );
};

export default CustomDateRangePicker;

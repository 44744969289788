import apiConfig from '../apiConfig';
import { useStore } from '../store/store';

export const UpdateUserDetails = async (userData) => {
  const { token, ...dataToSend } = userData;

  const authToken = useStore.getState().token;

  try {
    const response = await fetch(`${apiConfig.BASE_URL}${apiConfig.ENDPOINTS.UPDATE_USER_DATA}`, {
      method: 'POST',
      headers: {
        ...apiConfig.HEADERS,
        Authorization: `Bearer ${authToken}`
      },
      body: JSON.stringify(dataToSend)
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error updating user details:', error);
    throw error;
  }
};

export const fetchTags = async () => {
  try {
    const response = await fetch(`${apiConfig.BASE_URL}${apiConfig.ENDPOINTS.GET_TAGS}`, {
      headers: apiConfig.HEADERS
    });
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error fetching tags:', error);
    throw error;
  }
};

export const fetchAggregatedData = async (payload) => {
  try {
    const url = `${apiConfig.BASE_URL}${apiConfig.ENDPOINTS.GET_SAMPLES}`;

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching aggregated chart data:', error);
    return [];
  }
};

export const fetchData = async (startTime, tag) => {
  try {
    const response = await fetch(`${apiConfig.BASE_URL}${apiConfig.ENDPOINTS.S3_DATA}?startTime=${startTime}&tag=${tag}`, {
      headers: apiConfig.HEADERS
    });
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const fetchDataSingle = async (tag) => {
  try {
    const response = await fetch(`${apiConfig.BASE_URL}${apiConfig.ENDPOINTS.S3_DATA}?tag=${tag}`, {
      headers: apiConfig.HEADERS
    });
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const fetchAlarms = async (startTime) => {
  try {
    const response = await fetch(`${apiConfig.BASE_URL}${apiConfig.ENDPOINTS.GET_ALARMS}?startTime=${startTime}`, {
      headers: apiConfig.HEADERS
    });
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const fetchUserData = async () => {
  const token = useStore.getState().token;
  try {
    const response = await fetch(`${apiConfig.BASE_URL}${apiConfig.ENDPOINTS.GET_USER_DATA}`, {
      method: 'GET',
      headers: {
        ...apiConfig.HEADERS,
        Authorization: `Bearer ${token}`
      }
    });
    const result = await response.json();
    return result.user;
  } catch (error) {
    console.error('Error fetching user data:', error);
    throw error;
  }
};

export const createAlarm = async (alarmData) => {
  const token = useStore.getState().token;
  try {
    const response = await fetch(`${apiConfig.BASE_URL}${apiConfig.ENDPOINTS.CREATE_ALARM}`, {
      method: 'POST',
      headers: {
        ...apiConfig.HEADERS,
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(alarmData)
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error creating alarm:', error);
    throw error;
  }
};

export const readAlarms = async () => {
  const token = useStore.getState().token;
  try {
    const response = await fetch(`${apiConfig.BASE_URL}${apiConfig.ENDPOINTS.READ_ALARMS}`, {
      method: 'GET',
      headers: {
        ...apiConfig.HEADERS,
        Authorization: `Bearer ${token}`
      }
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error reading alarms:', error);
    throw error;
  }
};

export const updateAlarm = async (alarmData) => {
  const token = useStore.getState().token;
  try {
    const response = await fetch(`${apiConfig.BASE_URL}${apiConfig.ENDPOINTS.UPDATE_ALARM}`, {
      method: 'POST',
      headers: {
        ...apiConfig.HEADERS,
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({ alarmData })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error updating alarm:', error);
    throw error;
  }
};

export const deleteAlarm = async (timestamp, SampleName) => {
  const token = useStore.getState().token;
  try {
    const response = await fetch(`${apiConfig.BASE_URL}${apiConfig.ENDPOINTS.DELETE_ALARM}`, {
      method: 'POST',
      headers: {
        ...apiConfig.HEADERS,
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({ timestamp, SampleName })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error deleting alarm:', error);
    throw error;
  }
};

export const createAsset = async (assetData) => {
  const token = useStore.getState().token;
  try {
    const response = await fetch(`${apiConfig.BASE_URL}${apiConfig.ENDPOINTS.Create_ASSET}`, {
      method: 'POST',
      headers: {
        ...apiConfig.HEADERS,
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(assetData)
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error creating asset:', error);
    throw error;
  }
};

export const readAssets = async () => {
  const token = useStore.getState().token;
  try {
    const response = await fetch(`${apiConfig.BASE_URL}${apiConfig.ENDPOINTS.READ_ASSETS}`, {
      method: 'GET',
      headers: {
        ...apiConfig.HEADERS,
        Authorization: `Bearer ${token}`
      }
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error reading assets:', error);
    throw error;
  }
};

export const updateAsset = async (assetData) => {
  const token = useStore.getState().token;
  try {
    const response = await fetch(`${apiConfig.BASE_URL}${apiConfig.ENDPOINTS.UPDATE_ASSET}`, {
      method: 'POST',
      headers: {
        ...apiConfig.HEADERS,
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({ assetData })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error updating asset:', error);
    throw error;
  }
};

export const deleteAsset = async (timestamp, Name) => {
  const token = useStore.getState().token;
  try {
    const response = await fetch(`${apiConfig.BASE_URL}${apiConfig.ENDPOINTS.DELETE_ASSET}`, {
      method: 'POST',
      headers: {
        ...apiConfig.HEADERS,
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({ timestamp, Name })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error deleting asset:', error);
    throw error;
  }
};

export const readEvents = async (startTime, endTime) => {
  endTime = endTime || new Date().getTime();
  const token = useStore.getState().token;
  try {
    const response = await fetch(`${apiConfig.BASE_URL}${apiConfig.ENDPOINTS.READ_EVENTS}?startDate=${startTime}&endDate=${endTime}`, {
      method: 'GET',
      headers: {
        ...apiConfig.HEADERS,
        Authorization: `Bearer ${token}`
      }
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error reading alarms:', error);
    throw error;
  }
};

export const listUsers = async () => {
  const token = useStore.getState().token;
  try {
    const response = await fetch(`${apiConfig.BASE_URL}${apiConfig.ENDPOINTS.LIST_USERS}`, {
      method: 'GET',
      headers: {
        ...apiConfig.HEADERS,
        Authorization: `Bearer ${token}`
      }
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error reading alarms:', error);
    throw error;
  }
};

export const Notifications = async () => {
  const token = useStore.getState().token;
  try {
    const response = await fetch(`${apiConfig.BASE_URL}${apiConfig.ENDPOINTS.NOTIFICATOINS}`, {
      method: 'GET',
      headers: {
        ...apiConfig.HEADERS,
        Authorization: `Bearer ${token}`
      }
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error reading alarms:', error);
    throw error;
  }
};

export const updateNotifications = async (tsArray) => {
  const token = useStore.getState().token;
  try {
    const response = await fetch(`${apiConfig.BASE_URL}${apiConfig.ENDPOINTS.UPDATE_NOTIFICATIONS}`, {
      method: 'POST',
      headers: {
        ...apiConfig.HEADERS,
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({ tsArray })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error deleting asset:', error);
    throw error;
  }
};

export const createUser = async (user) => {
  const token = useStore.getState().token;
  try {
    const response = await fetch(`${apiConfig.BASE_URL}${apiConfig.ENDPOINTS.CREATE_USER}`, {
      method: 'POST',
      headers: {
        ...apiConfig.HEADERS,
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({ user })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error deleting asset:', error);
    throw error;
  }
};

export const updateUserPermissions = async (email, permission, isChecked) => {
  const token = useStore.getState().token;
  try {
    const response = await fetch(`${apiConfig.BASE_URL}${apiConfig.ENDPOINTS.UPDATE_USER_PERMISSIONS}`, {
      method: 'POST',
      headers: {
        ...apiConfig.HEADERS,
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({ email, permission, isChecked })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error updating user permissions:', error);
    throw error;
  }
};

export const login = async (email, password) => {
  const response = await fetch(`${apiConfig.BASE_URL}${apiConfig.ENDPOINTS.LOGIN}`, {
    method: 'POST',
    headers: {
      ...apiConfig.HEADERS
    },
    body: JSON.stringify({ email, password })
  });

  if (!response.ok) {
    let errorMessage = 'An error occurred while processing your request. Please try again.';
    try {
      const data = await response.json();
      errorMessage = data.message || data.error;
    } catch (error) {
      // Optionally handle JSON parsing error if needed
    }
    throw new Error(errorMessage);
  }

  return response.json();
};

export const register = async (email, password) => {
  const response = await fetch(`${apiConfig.BASE_URL}${apiConfig.ENDPOINTS.REGISTER}`, {
    method: 'POST',
    headers: {
      ...apiConfig.HEADERS
    },
    body: JSON.stringify({ email, password })
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return await response.json();
};

export const updateUser = async (userData) => {
  const token = useStore.getState().token;
  try {
    const response = await fetch(`${apiConfig.BASE_URL}${apiConfig.ENDPOINTS.UPDATE_USER_DATA}`, {
      method: 'POST',
      headers: {
        ...apiConfig.HEADERS,
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(userData)
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error updating user:', error);
    throw error;
  }
}
;

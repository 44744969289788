
import React, { useState } from 'react';
import { Box, Container } from '@mui/material';
import Navigation from './Navigation';
import AboutUs from './AboutUs';
import Product from './Product';
import Ctontact from './Contact';
import Login from './Login';
import { Route, Routes } from 'react-router-dom';

const MainPage = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Navigation value={value} handleChange={handleChange} />
      <Container>
        <Box id='main-page' sx={{ flexGrow: 1 }}>
          <Routes>
            <Route path='/' element={<AboutUs />} />
            <Route path='/about' element={<AboutUs />} />
            <Route path='/contact' element={<Ctontact />} />
            <Route path='/product' element={<Product />} />
            <Route path='/login' element={<Login />} />
            <Route path='*' element={<h1>Not Found</h1>} />
          </Routes>
        </Box>
      </Container>
    </>
  );
};

export default MainPage;

import React from 'react';
import {
  Box,
  Container,
  Typography,
  Card,
  CardContent,
  Link
} from '@mui/material';
import { styled } from '@mui/material/styles';

// Custom styled components
const ImageContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: theme.spacing(4, 0)
}));

const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline'
  },
  color: theme.palette.secondary.main
}));

const CustomCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  boxShadow: theme.shadows[3],
  padding: theme.spacing(3)
}));

const AboutUs = () => {
  return (
    <Container maxWidth='lg'>
      <ImageContainer>
        <img
          src='./asenconnect.png'
          alt='Asenco Logo'
          style={{
            maxWidth: '100%',
            height: 'auto',
            maxHeight: '400px' // Ensure the image is not too large on any screen
          }}
        />
      </ImageContainer>

      <Typography variant='body1' component='p' gutterBottom align='center' mb={2}>
        <StyledLink
          href='https://www.asenco.com.tr'
          target='_blank'
          rel='noopener noreferrer'
        >
          www.asenco.com.tr
        </StyledLink>
      </Typography>

      <CustomCard>
        <CardContent>
          <Typography variant='h6' gutterBottom>
            Your Guide in the Digital Transformation Journey...
          </Typography>
          <Typography>
            Do you know where to start on your digital transformation journey? ASENCO IoT Platform is an IoT Application Enablement Platform that allows for fast, scalable, and secure connection of devices to the internet using multiple protocols and encryption.
          </Typography>
        </CardContent>
      </CustomCard>
    </Container>
  );
};

export default AboutUs;

import React from 'react';
import { CircularProgress, Box } from '@mui/material';
import UserProfileForm from './UserProfileForm';
import useUserProfile from './useUserProfile';

const Profile = () => {
  const { formData, handleChange, handleSubmit, state, error } = useUserProfile();

  return (
    <Box p={3} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Box>
        {state === 'LOADING'
          ? (
            <CircularProgress />
            )
          : (
            <UserProfileForm
              formData={formData}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              error={error}
            />
            )}
      </Box>
    </Box>
  );
};

export default Profile;

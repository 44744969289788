import React, { useState, useEffect } from 'react';
import { Typography, Grid, Modal, Box, FormControl, InputLabel, Select, MenuItem, TextField, Button, Divider } from '@mui/material';
import DatePicker from '@mui/lab/DatePicker';
import { useStore } from '../../store/store';
import useLayoutsStore from '../../store/layoutsStore';
import { useSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import DateRangeSelector from '../DateRangeSelector';
const GaugeSelector = ({ samples, setSamples }) => {
  const { samplePoints } = useStore(state => ({
    samplePoints: state.getSamplePoints()
  }));

  const [selectedSampleLimits, setSelectedSampleLimits] = useState({ lower: '', upper: '' });
  const initialSampleName = samples[0]?.name || '';

  const handleSampleChange = (event) => {
    const selectedSample = samplePoints.find(s => s.SampleName === event.target.value);
    const newSample = {
      name: selectedSample.SampleName,
      chartType: 'gauge',
      SampleLowerLimit: selectedSample.SampleLowerLimit,
      SampleUpperLimit: selectedSample.SampleUpperLimit,
      showBrush: false
    };
    setSamples([newSample]);
    setSelectedSampleLimits({
      lower: selectedSample.SampleLowerLimit,
      upper: selectedSample.SampleUpperLimit
    });
  };

  return (
    <div>
      <FormControl fullWidth style={{ marginTop: 10 }} variant='outlined'>
        <InputLabel id='sample-names-label'>Sample Name</InputLabel>
        <Select
          labelId='sample-names-label'
          label='Sample Name'
          value={initialSampleName}
          onChange={handleSampleChange}
        >
          {samplePoints.map((samplePoint, index) => (
            <MenuItem key={index} value={samplePoint.SampleName}>
              {samplePoint.SampleName}
            </MenuItem>
          ))}
        </Select>
        {(initialSampleName) &&
          (
            <Box mt={2} p={2} border={1} borderColor='grey.300' borderRadius={4}>
              <Typography variant='body1'>
                Lower Limit: {selectedSampleLimits?.lower}
              </Typography>
              <Typography variant='body1'>
                Upper Limit: {selectedSampleLimits?.upper}
              </Typography>
            </Box>
          )}
      </FormControl>
    </div>
  );
};

const TableSelector = ({ samples, setSamples }) => {
  const { samplePoints } = useStore(state => ({
    samplePoints: state.getSamplePoints()
  }));
  const graphicType = 'table';
  const initialSampleName = samples[0]?.name || '';

  const handleSingleSampleChange = (event, chartType) => {
    const selectedSample = event.target.value;
    const existingSample = samples.find(sample => sample.name === selectedSample);
    const newSample = existingSample || { name: selectedSample, chartType: chartType };
    setSamples([newSample]);
  };

  return (
    <div>
      <FormControl fullWidth style={{ marginTop: 10 }} variant='outlined'>
        <InputLabel id='sample-names-label'>Sample Name</InputLabel>
        <Select
          labelId='sample-names-label'
          label='Sample Name'
          value={initialSampleName}
          onChange={(event) => {
            handleSingleSampleChange(event, graphicType);
          }}
        >
          {samplePoints.map((samplePoint, index) => (
            <MenuItem key={index} value={samplePoint.SampleName}>
              {samplePoint.SampleName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

const ChartSelector = ({ dateRange, setDateRange, samples, setSamples }) => {
  const { samplePoints } = useStore(state => ({
    samplePoints: state.getSamplePoints()
  }));
  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleRangeChange = (event) => {
    setDateRange(event.target.value);
    setSelectedDate(null);
  };

  const handleSampleChange = (event) => {
    const selectedSamples = event.target.value.map(name => {
      const existingSample = samplePoints.find(sample => sample.SampleName === name);
      return {
        name: existingSample.SampleName,
        chartType: 'line',
        SampleLowerLimit: existingSample.SampleLowerLimit,
        SampleUpperLimit: existingSample.SampleUpperLimit
      };
    });
    setSamples(selectedSamples);
  };
  const handleChartTypeChange = (sampleName, chartType) => {
    setSamples(samples.map(sample => sample.name === sampleName ? { ...sample, chartType } : sample));
  };
  const handleRemoveSample = (sampleName) => {
    const updatedSamples = samples.filter(sample => sample.name !== sampleName);
    setSamples(updatedSamples);
  };
  return (
    <div>
      <DateRangeSelector
        dateRange={dateRange}
        handleRangeChange={handleRangeChange}
      />
      <DatePicker
        label='Custom Start Date'
        value={selectedDate}
        onChange={handleDateChange}
        renderInput={(params) => <TextField {...params} fullWidth />}
        disabled={dateRange !== 'custom'}
      />
      <FormControl fullWidth style={{ marginTop: 10 }} variant='outlined'>
        <InputLabel id='sample-names-label'>Sample Names</InputLabel>
        <Select
          labelId='sample-names-label'
          label='Sample Names'
          multiple
          value={samples.map(sample => sample.name)}
          onChange={handleSampleChange}
        >
          {samplePoints.map((samplePoint, index) => (
            <MenuItem key={index} value={samplePoint.SampleName}>
              {samplePoint.SampleName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {samples.map((sample, index) => (
        <Grid container alignItems='center' spacing={2} style={{ marginTop: 10 }} key={index}>
          <Grid item>
            <InputLabel>{sample.name}</InputLabel>
          </Grid>
          <Grid item>
            <FormControl variant='outlined'>
              <Select
                labelId={`chart-type-label-${index}`}
                value={sample.chartType}
                onChange={(event) => handleChartTypeChange(sample.name, event.target.value)}
                style={{ minWidth: 120 }}
              >
                <MenuItem value='line'>Line</MenuItem>
                <MenuItem value='bar'>Bar</MenuItem>
                <MenuItem value='scatter'>Scatter</MenuItem>
                <MenuItem value='area'>Area</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <Typography variant='body1'>
              Show Brush checkbox
            </Typography>
            <input
              type='checkbox'
              checked={sample.showBrush}
              onChange={(e) => setSamples(samples.map(s => s.name === sample.name ? { ...s, showBrush: e.target.checked } : s))}
            />
          </Grid>
          <Grid item>
            <Button onClick={() => handleRemoveSample(sample.name)}>
              <CloseIcon />
            </Button>
          </Grid>
        </Grid>
      ))}
    </div>
  );
};

const ModalComponent = () => {
  const [graphicType, setGraphicType] = useState('chart');
  const { isChartModalOpen, closeChartModal } = useStore(state => ({
    isChartModalOpen: state.isChartModalOpen,
    closeChartModal: state.closeChartModal
  }));

  const { getLayouts, setLayouts, chartData } = useLayoutsStore(state => ({
    getLayouts: state.getLayouts,
    setLayouts: state.setLayouts,
    chartData: state.chartData
  }));

  const [samples, setSamples] = useState(chartData?.samples || []);
  const [dateRange, setDateRange] = useState(chartData?.dateRange || '1h');
  const [chartName, setChartName] = useState(chartData?.chartName || '');

  const layouts = getLayouts();

  const { enqueueSnackbar } = useSnackbar();

  const handleGraphicTypeChange = (event) => {
    setGraphicType(event.target.value);
  };

  // Add or Update Chart
  const handleSaveChart = () => {
    if (samples.length === 0) {
      enqueueSnackbar('Please fill all required fields');
      return;
    }

    const chartConfig = { samples, dateRange, chartName };
    if (chartData) {
      const updatedLayouts = layouts.map((layout) => {
        if (layout.i === chartData.id) {
          chartConfig.id = chartData.id;
          return ({ ...layout, chartConfig });
        } else {
          return layout;
        }
      }
      );
      setLayouts(updatedLayouts);
    } else {
      // Existing add logic
      const uuid = Math.random().toString(36);
      chartConfig.id = uuid;
      const newLayout = {
        i: uuid,
        x: 0,
        y: 0,
        w: 3,
        h: 6,
        chartConfig,
        minW: 3,
        minH: 6
      };
      setLayouts([...layouts, newLayout]);
    }
    closeChartModal();
  };

  useEffect(() => {
    setSamples(chartData?.samples || []);
    setDateRange(chartData?.dateRange || '1h');
    setChartName(chartData?.chartName || '');
    setGraphicType('chart'); // Resetting graphic type to its initial state
  }, [chartData, isChartModalOpen]); // Add isChartModalOpen to the dependency array

  return (
    <Modal
      open={isChartModalOpen}
      onClose={closeChartModal}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 600,
          maxHeight: '100vh',
          overflowY: 'auto',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          m: 2
        }}
      >
        {!chartData && <h2 id='modal-modal-title'>Select Chart Options</h2>}
        {!!chartData && <h2 id='modal-modal-title'>Update Chart Options</h2>}
        <FormControl fullWidth style={{ marginTop: 10 }} variant='outlined'>
          <TextField
            label='Chart Name'
            value={chartName}
            onChange={(e) => setChartName(e.target.value)}
            variant='outlined'
          />
        </FormControl>
        <Divider style={{ marginTop: 10 }} />
        <FormControl fullWidth style={{ marginTop: 10 }} variant='outlined'>
          <InputLabel id='graphic-type-label'>Graphic Type</InputLabel>
          <Select
            id='graphic-typee'
            label='Graphic Type'
            labelId='graphic-type-label'
            value={graphicType}
            onChange={handleGraphicTypeChange}
          >
            <MenuItem value='chart'>Chart</MenuItem>
            <MenuItem value='table'>Table</MenuItem>
            <MenuItem value='gauge'>Gauge</MenuItem>
          </Select>
        </FormControl>
        <Divider style={{ marginTop: 10 }} />
        {graphicType === 'chart' && (
          <ChartSelector
            setDateRange={setDateRange}
            dateRange={dateRange}
            samples={samples}
            setSamples={setSamples}
          />
        )}
        {(graphicType === 'table') && (
          <TableSelector
            samples={samples}
            setSamples={setSamples}
          />
        )}
        {(graphicType === 'gauge') && (
          <GaugeSelector
            samples={samples}
            setSamples={setSamples}
          />
        )}
        <div style={{ marginTop: 10, float: 'right' }}>
          <Button
            variant='contained'
            color='primary'
            style={{ marginLeft: 10 }}
            onClick={handleSaveChart}
          >
            {chartData ? 'Update Chart' : 'Add Chart'}
          </Button>
          <Button
            variant='contained'
            color='secondary'
            style={{ marginLeft: 10 }}
            onClick={closeChartModal}
          >
            Close
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default ModalComponent;

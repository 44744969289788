import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import {
  Tooltip,
  Badge,
  IconButton,
  Popover,
  List,
  ListItem,
  ListItemText,
  Divider
} from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import useNotificationStore from '../../store/notificationStore';

const NotificationBell = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const notifications = useNotificationStore((state) => state.notifications);
  const fetchAndUpdateNotifications = useNotificationStore((state) => state.fetchAndUpdateNotifications);
  const markNotificationsSeenLocally = useNotificationStore((state) => state.markNotificationsSeenLocally);

  useEffect(() => {
    fetchAndUpdateNotifications();
    const interval = setInterval(fetchAndUpdateNotifications, 15000);
    return () => clearInterval(interval);
  }, [fetchAndUpdateNotifications]);

  const handleNotificationClick = (event) => {
    setAnchorEl(event.currentTarget);
    markNotificationsSeenLocally();
  };

  const handleNotificationClose = () => {
    setAnchorEl(null);
  };

  const handleNotificationItemClick = () => {
    navigate('/events'); // Redirect user to '/events' URL
    handleNotificationClose(); // Close the popover after redirection
  };

  const open = Boolean(anchorEl);
  const id = open ? 'notification-popover' : undefined;

  return (
    <>
      <Tooltip title='Notifications' arrow>
        <IconButton
          aria-label='notifications'
          onClick={handleNotificationClick}
          color='inherit'
        >
          <Badge badgeContent={notifications.filter(notif => !notif.seen).length} color='error'>
            <NotificationsIcon />
          </Badge>
        </IconButton>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleNotificationClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <List sx={{ maxWidth: 360 }}>
          {notifications.map((notification) => (
            <React.Fragment key={notification.ts}>
              <ListItem button onClick={handleNotificationItemClick}>
                <ListItemText primary={notification.SampleName} secondary={`Value: ${notification.value}`} />
              </ListItem>
              <Divider />
            </React.Fragment>
          ))}
          {notifications.length === 0 && (
            <ListItem>
              <ListItemText primary='No new notifications' />
            </ListItem>
          )}
        </List>
      </Popover>
    </>
  );
};

export default NotificationBell;

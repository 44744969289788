import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { useStore } from '../store/store';
import Login from '../publicPage/Login';
import Dashboard from './dashboard/Dashboard';
import Devices from './devices/Devices';
import Events from './events/Events';
import AdminPanel from './adminpanel/AdminPanel';
import Assets from './assets/Assets';
import Profile from './profile/Profile';
import { useMediaQuery } from '@mui/material';
import Alarms from './alarms/Alarms';

const MainContent = () => {
  const { isLoggedIn } = useStore(state => ({
    isLoggedIn: state.isLoggedIn
  }));
  const isMobile = useMediaQuery('(max-width:600px)');

  const style = {
    left: isMobile ? 0 : '200px',
    position: 'absolute',
    top: '64px',
    width: isMobile ? '100%' : 'calc(100% - 200px)',
    backgroundColor: '#F0F0F0',
    paddingRight: '50px',
    height: '100%',
    overflow: 'scroll',
    paddingBottom: '100px'
  };

  return (
    <main style={isLoggedIn ? style : {}}>
      <Routes>
        <Route path='/login' element={<Login />} />
        <Route path='/' element={<Dashboard />} />
        <Route path='/devices' element={<Devices />} />
        <Route path='/events' element={<Events />} />
        <Route path='/admin' element={<AdminPanel />} />
        <Route path='/assets' element={<Assets />} />
        <Route path='/profile' element={<Profile />} />
        <Route path='/alarms' element={<Alarms />} />
        <Route path='*' element={<h1>Not Found</h1>} />
      </Routes>
    </main>
  );
};

export default MainContent;

import { useState, useEffect } from 'react';
import { useStore } from '../../store/store';
import { useSnackbar } from 'notistack';
import { UpdateUserDetails, fetchUserData } from '../../apiservice/apiService';

const STATES = {
  loading: 'LOADING',
  error: 'ERROR',
  success: 'SUCCESS'
};

const useUserProfile = () => {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    companyName: '',
    companyLogoUrl: '',
    secomeaJSON: ''
  });
  const [state, setState] = useState(STATES.loading);
  const [error, setError] = useState(null);
  const token = useStore((state) => state.token);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchData = async () => {
      if (!token) {
        setState(STATES.error);
        setError('No token found');
        enqueueSnackbar('No token found', { variant: 'error' });
        return;
      }

      try {
        const userData = await fetchUserData(token);
        if (userData) {
          setFormData({
            username: userData.username || '',
            email: userData.email || '',
            companyName: userData.companyName || '',
            companyLogoUrl: userData.companyLogoUrl || '',
            secomeaJSON: userData.secomeaJSON || ''
          });
          setState(STATES.success);
        } else {
          setState(STATES.error);
          setError('User data not found');
          enqueueSnackbar('User data not found', { variant: 'error' });
        }
      } catch (error) {
        setState(STATES.error);
        setError(error.message || 'An error occurred while fetching user data');
        enqueueSnackbar(error.message || 'An error occurred while fetching user data', { variant: 'error' });
      }
    };

    fetchData();
  }, [token, enqueueSnackbar]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    enqueueSnackbar('Saving Changes', { variant: 'info' });
    try {
      const { email, ...dataWithoutEmail } = formData;
      await UpdateUserDetails({ ...dataWithoutEmail, token });

      enqueueSnackbar('Changes Saved Successfully', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Error Saving Changes', { variant: 'error' });
      setError(error.message || 'An error occurred');
    }
  };

  return { formData, handleChange, handleSubmit, state, error };
};

export default useUserProfile;

import React, { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  Input,
  InputLabel,
  Typography,
  Link,
  Paper
} from '@mui/material';
import { useStore } from '../store/store.js';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { login, register } from '../apiservice/apiService.js';

const LoginRegister = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isRegistering, setIsRegistering] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirmPassword: ''
  });
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const handleLogin = useStore(state => state.handleLogin);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (isRegistering && formData.password !== formData.confirmPassword) {
      enqueueSnackbar("Passwords don't match", { variant: 'error' });
      return;
    }
    try {
      let result;
      if (isRegistering) {
        result = await register(formData.email, formData.password);
      }
      if (!isRegistering) {
        result = await login(formData.email, formData.password);
      }

      handleLogin({ ...result });
      navigate('/');
    } catch (error) {
      enqueueSnackbar(error.message || 'An error occurred', { variant: 'error' });
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }));
  };

  const handleLinkClick = () => {
    setIsRegistering((prevState) => !prevState);
  };

  return (
    <div style={{
      marginBottom: '5px',
      marginTop: '3%'
    }}
    >
      <Paper elevation={3} sx={{ width: '90%', maxWidth: 400, margin: 'auto', padding: 3, borderRadius: 2, marginTop: '5%' }}>
        <Box
          component='form'
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
          onSubmit={handleSubmit}
        >
          <Typography variant='h5' mb={2} sx={{ fontWeight: 'bold', color: '#505050' }}>
            {isRegistering ? 'Register' : 'Login'}
          </Typography>
          <FormControl sx={{ mb: 2, width: '100%' }} required>
            <InputLabel htmlFor='email'>Email</InputLabel>
            <Input
              id='email'
              name='email'
              type='email'
              value={formData.email}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl sx={{ mb: 2, width: '100%' }} required>
            <InputLabel htmlFor='password'>Password</InputLabel>
            <Input
              id='password'
              name='password'
              type='password'
              value={formData.password}
              onChange={handleInputChange}
            />
          </FormControl>
          {isRegistering && (
            <FormControl sx={{ mb: 2, width: '100%' }} required>
              <InputLabel htmlFor='confirmPassword'>Confirm Password</InputLabel>
              <Input
                id='confirmPassword'
                name='confirmPassword'
                type='password'
                value={formData.confirmPassword}
                onChange={handleInputChange}
              />
            </FormControl>
          )}
          <Button type='submit' variant='contained' color='primary' sx={{ mt: 2, width: '100%' }} disabled={isLoading}>
            {isLoading ? 'Loading...' : (isRegistering ? 'Register' : 'Login')}
          </Button>
          {false && // hide register link, create register for admins
          (<Box sx={{ mt: 2, textAlign: 'center' }}>
            ({isRegistering
              ? (
                <Typography variant='body2'>
                  Already registered?{' '}
                  <Link href='#' onClick={handleLinkClick}>
                    Login
                  </Link>
                </Typography>
                )
              : (
                <Typography variant='body2'>
                  Not registered yet?{' '}
                  <Link href='#' onClick={handleLinkClick}>
                    Register
                  </Link>
                </Typography>
                )})
            <Typography variant='body2' sx={{ mt: 1 }}>
              <Link href='#'>Forgot password?</Link>
            </Typography>
           </Box>
          )}
        </Box>
      </Paper>
    </div>
  );
};

export default LoginRegister;

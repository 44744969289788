import { create } from 'zustand';
import { persist } from 'zustand/middleware';

// remote server
// process.env.REACT_APP_API_HOST = 'https://ip59nu135m.execute-api.us-east-1.amazonaws.com/prod';

export const useStore = create(
  persist(
    (set, get) => ({
      username: '',
      email: '',
      handleLogout: () => {
        set({
          isLoggedIn: false,
          username: '',
          email: '',
          secomeaJSON: '',
          token: '',
          layouts: '[]',
          companyName: '',
          companyLogoUrl: ''
        });
      },

      handleLogin: ({ username, email, secomeaJSON, companyName, token, dashboardLayout, companyLogoUrl }) => {
        set({
          isLoggedIn: true,
          username: username,
          email: email,
          secomeaJSON: secomeaJSON,
          token: token,
          layouts: dashboardLayout || '[]',
          companyName: companyName,
          companyLogoUrl
        });
      },
      getUser: () => {
        return {
          isLoggedIn: get().isLoggedIn,
          username: get().username,
          email: get().email,
          token: get().token,
          layouts: get().layouts,
          companyName: get().companyName,
          secomeaJSON: get().secomeaJSON,
          companyLogoUrl: get().companyLogoUrl
        };
      },

      getSamplePoints: () => {
        if (!get().secomeaJSON) {
          return [];
        }
        try {
          const secomeaJSON = JSON.parse(get().secomeaJSON);
          if (!secomeaJSON?.Collectors || secomeaJSON.Collectors.length === 0) {
            return [];
          }
          return secomeaJSON.Collectors.reduce((samplePoints, collector) => {
            return [...samplePoints, ...collector.SamplePoints];
          }, []);
        } catch (error) {
          console.log('error', error);
          return [];
        }
      },

      isChartModalOpen: false,
      openChartModal: (chartData = null) => set({ isChartModalOpen: true, chartData }),
      closeChartModal: () => set({ isChartModalOpen: false, chartData: null }),

      drawerOpen: false,
      toggleDrawer: () => set((state) => ({ drawerOpen: !state.drawerOpen })),

      isLoggedIn: false,
      setIsLoggedIn: (isLoggedIn) => set({ isLoggedIn })
    }),
    {
      name: 'general-store',
      getStorage: () => window.localStorage
    }
  )
);

export default useStore;

import React, { useEffect, useState, useCallback } from 'react';
import { Box, Button, Divider } from '@mui/material';
import FiltersForm from './FiltersForm.js';
import { readEvents } from '../../apiservice/apiService';
import CustomDateRangePicker from './CustomDateRangePicker';
import EventsTable from './EventsTable.js';
import moment from 'moment';

function Events () {
  const [allEvents, setAllEvents] = useState([]);
  const [events, setEvents] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);

  const [filters, setFilters] = useState({
    eventType: '',
    maxValue: undefined,
    minValue: undefined
  });

  const [dateRange, setDateRange] = useState({
    startDate: moment().subtract(5, 'minutes').valueOf(),
    endDate: moment().valueOf()
  });

  const handleDateChange = ({ startDate, endDate }) => {
    setDateRange({ startDate, endDate });
  };

  const loadEvents = useCallback(async () => {
    try {
      const response = await readEvents(dateRange.startDate, dateRange.endDate);
      setAllEvents(response.results);
    } catch (error) {
      console.error('Failed to fetch events:', error);
    }
  }, [dateRange.startDate, dateRange.endDate]);

  useEffect(() => {
    loadEvents();
  }, [loadEvents, dateRange.startDate, dateRange.endDate]);

  useEffect(() => {
    const filteredEvents = allEvents.filter(event => {
      let matchesFilters = true;
      if (filters.eventType && event.type !== filters.eventType) {
        matchesFilters = false;
      }
      if (filters.maxValue !== undefined && event.value > parseFloat(filters.maxValue)) {
        matchesFilters = false;
      }
      if (filters.minValue !== undefined && event.value < parseFloat(filters.minValue)) {
        matchesFilters = false;
      }

      return matchesFilters;
    });

    setEvents(filteredEvents);
  }, [allEvents, filters]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFiltersChange = (newFilters) => {
    setFilters(newFilters);
  };

  return (
    <Box p={3} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: 2,
        alignItems: 'center',
        justifyContent: 'flex-start'
      }}
      >
        <CustomDateRangePicker onDateChange={handleDateChange} />
        <Button
          id='load-events'
          variant='contained' color='primary' onClick={loadEvents}
        >
          Load Events
        </Button>
      </Box>
      <Divider />
      <FiltersForm onFiltersChange={handleFiltersChange} />
      <EventsTable
        events={events.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        count={events.length}
      />
    </Box>
  );
}

export default Events;

import React from 'react';
import { Box, Typography, Link, Grid, Paper, useTheme, styled } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import BusinessIcon from '@mui/icons-material/Business';
import WebIcon from '@mui/icons-material/Web';

const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline'
  },
  color: theme.palette.primary.main
}));

const ContactItem = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2)
}));

const Contact = () => {
  const theme = useTheme();

  return (
    <Paper elevation={6} sx={{ p: theme.spacing(6), my: theme.spacing(6), backgroundColor: theme.palette.background.paper }}>
      <Box sx={{ textAlign: 'center', mb: theme.spacing(7) }}>
        <Typography variant='h4' component='h2' gutterBottom sx={{ fontWeight: 'bold' }}>
          Contact ASENCO Engineering & Consultancy
        </Typography>
        <Typography variant='subtitle1' sx={{ color: theme.palette.text.secondary }}>
          Get in touch for tailored IoT solutions that drive digital transformation.
        </Typography>
      </Box>

      <Grid container spacing={4} justifyContent='center'>
        <Grid item xs={12} sm={6} lg={3}>
          <ContactItem>
            <Typography variant='h6' gutterBottom sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
              <BusinessIcon sx={{ mr: 1 }} /> Address
            </Typography>
            <Typography variant='body1'>Onur Mah. Tekel Cad. Kiza İş Merkezi A1 Blok No:1</Typography>
            <Typography variant='body1'>İç Kapı No:302</Typography>
            <Typography variant='body1'>Seyhan/Adana</Typography>
          </ContactItem>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <ContactItem>
            <Typography variant='h6' gutterBottom sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
              <PhoneIcon sx={{ mr: 1 }} /> Phone
            </Typography>
            <StyledLink href='tel:+903225046836'>
              +90 322 504 68 36
            </StyledLink>
          </ContactItem>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <ContactItem>
            <Typography variant='h6' gutterBottom sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
              <EmailIcon sx={{ mr: 1 }} /> Email
            </Typography>
            <StyledLink href='mailto:info@asenco.com.tr'>
              info@asenco.com.tr
            </StyledLink>
          </ContactItem>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <ContactItem>
            <Typography variant='h6' gutterBottom sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
              <WebIcon sx={{ mr: 1 }} /> Website
            </Typography>
            <StyledLink href='http://www.asenco.com.tr' target='_blank' rel='noopener noreferrer'>
              www.asenco.com.tr
            </StyledLink>
          </ContactItem>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Contact;

import React, { useState } from 'react';
import { Grid, Button, FormControl, TextField, InputLabel, Select, MenuItem, Paper } from '@mui/material';
import { useStore } from '../../store/store';

const AlarmForm = ({ onSetAlarm }) => {
  const samplePoints = useStore(state => state.getSamplePoints());
  const [selectedSampleForAlarm, setSelectedSampleForAlarm] = useState('');
  const [alarmType, setAlarmType] = useState('');
  const [minValue, setMinValue] = useState('0');
  const [maxValue, setMaxValue] = useState('0');
  const [Name, setName] = useState('');

  const handleAlarmTypeChange = (event) => {
    setAlarmType(event.target.value);
  };

  const handleMinValueChange = (event) => {
    setMinValue(event.target.value);
  };

  const handleMaxValueChange = (event) => {
    setMaxValue(event.target.value);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleSetAlarmClick = () => {
    onSetAlarm({
      type: alarmType,
      minValue: isNaN(Number(minValue)) ? 0 : Number(minValue),
      maxValue: isNaN(Number(maxValue)) ? 0 : Number(maxValue),
      SampleName: selectedSampleForAlarm,
      Name: Name
    });
    setSelectedSampleForAlarm('');
    setAlarmType('');
    setMinValue('');
    setMaxValue('');
    setName('');
  };

  return (
    <Paper style={{ padding: '10px', marginBottom: '10px', backgroundColor: '#f5f5f5' }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <InputLabel id='alarm-sample-label'>Select Sample</InputLabel>
            <Select
              labelId='alarm-sample-label'
              value={selectedSampleForAlarm}
              label='Select Sample'
              onChange={(e) => setSelectedSampleForAlarm(e.target.value)}
            >
              {samplePoints.map((samplePoint, index) => (
                <MenuItem key={index} value={samplePoint.SampleName}>
                  {samplePoint.SampleName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={2}>
          <FormControl fullWidth>
            <InputLabel id='alarm-type-label'>Alarm Type</InputLabel>
            <Select
              labelId='alarm-type-label'
              value={alarmType}
              label='Alarm Type'
              onChange={handleAlarmTypeChange}
            >
              <MenuItem value='Red'>Red</MenuItem>
              <MenuItem value='Yellow'>Yellow</MenuItem>
              <MenuItem value='Green'>Green</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={2}>
          <TextField
            label='Min Value'
            fullWidth
            variant='outlined'
            value={minValue}
            onChange={handleMinValueChange}
            placeholder='0.0'
            type='number'
          />
        </Grid>

        <Grid item xs={12} md={2}>
          <TextField
            label='Max Value'
            fullWidth
            variant='outlined'
            value={maxValue}
            onChange={handleMaxValueChange}
            placeholder='0.0'
            type='number'
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <TextField
            label='Name'
            fullWidth
            variant='outlined'
            value={Name}
            onChange={handleNameChange}
            placeholder=''
            type='text'
          />
        </Grid>
        <Grid item xs={12}>
          <Button variant='contained' onClick={handleSetAlarmClick}>
            Set Alarm
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default AlarmForm;

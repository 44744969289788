import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';

const ResetPasswordDialog = ({ open, onClose, onResetPassword }) => {
  const handleResetPassword = () => {
    // Call the function that will handle the password reset logic
    onResetPassword();
    onClose(); // Close the dialog after the action
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Reset Password</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to reset the password for this user? An email will be sent to the user with instructions on how to set a new password.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleResetPassword} autoFocus>
          Reset Password
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ResetPasswordDialog;

import React, { useEffect, useState, useCallback } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import _ from 'lodash';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import Chart from './Chart';
import DataTable from '../assets/DataTable';
import GaugeChart from './GaugeChart';
import SettingsIcon from '@mui/icons-material/Settings';
import CloseIcon from '@mui/icons-material/Close';
import { fetchAggregatedData } from '../../apiservice/apiService';
import { calculateStartTime } from '../helpers';
import { useStore } from '../../store/store';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const buttonStyle = {
  position: 'absolute',
  top: '1px',
  cursor: 'pointer',
  backgroundImage: 'none',
  width: '25px',
  height: '25px',
  border: 0,
  padding: '2px',
  zIndex: 1000
};

const adjustButtonStyle = {
  ...buttonStyle,
  left: '1px'
};

const removeButtonStyle = {
  ...buttonStyle,
  right: '1px'
};

const settingsButtonStyle = {
  ...buttonStyle,
  right: '27px'
};

const ChartsGrid = ({ layouts, onLayoutChange, onLayoutRemove, ...props }) => {
  const [chartData, setChartData] = useState({});
  const { openChartModal } = useStore(state => ({
    openChartModal: state.openChartModal
  }));

  useEffect(() => {
    const fetchAllChartData = async () => {
      const requestPayload = layouts.lg.map(layout => ({
        id: layout.i,
        startTime: calculateStartTime(layout?.chartConfig?.dateRange) + (layout?.chartConfig?.dateAdjustment || 0) * 3600000,
        samples: layout?.chartConfig?.samples
      }));
      try {
        const response = await fetchAggregatedData(requestPayload);
        setChartData(response);
      } catch (error) {
        console.error('Error fetching chart data:', error);
      }
    };
    fetchAllChartData();
    const interval = setInterval(fetchAllChartData, 60000);
    return () => clearInterval(interval);
  }, [layouts]);

  const handleRemove = useCallback((layoutId) => {
    const newLayouts = _.cloneDeep(layouts);
    _.remove(newLayouts.lg, l => l.i === layoutId);
    onLayoutRemove(newLayouts);
  }, [layouts, onLayoutRemove]);

  const adjustStartDate = useCallback((layoutId, hours) => {
    const newLayouts = _.cloneDeep(layouts);
    const layout = newLayouts.lg.find(l => l.i === layoutId);
    if (layout) {
      layout.chartConfig.dateAdjustment = (layout.chartConfig.dateAdjustment || 0) + hours;
      onLayoutChange([layout], newLayouts);
    }
  }, [layouts, onLayoutChange]);

  const defaultProps = {
    className: 'layout',
    rowHeight: 30,
    cols: { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },
    breakpoints: { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 },
    containerPadding: [0, 0]
  };

  return (
    <ResponsiveReactGridLayout
      layouts={layouts}
      measureBeforeMount={false}
      useCSSTransforms
      onLayoutChange={onLayoutChange}
      {...defaultProps}
    >
      {layouts?.lg?.map((layout) => (
        <div key={layout.i} style={{ position: 'relative' }}>
          <span className='tagName' style={{ paddingLeft: 15 }}>
            Tag: {layout?.chartConfig?.samples.map(sample => sample.name).join(', ')}
          </span>
          {layout?.chartConfig?.samples[0].chartType === 'table' && chartData[layout.i]?.length > 0 && (
            chartData[layout.i] && <DataTable data={chartData[layout.i]} />
          )}
          {layout?.chartConfig?.samples[0]?.chartType === 'gauge' && (
            chartData[layout.i] && <GaugeChart
              min={layout.chartConfig.samples[0].SampleLowerLimit}
              max={layout.chartConfig.samples[0].SampleUpperLimit}
              currentValue={chartData[layout.i][0]?.val}
                                   />
          )}
          {layout?.chartConfig?.samples[0]?.chartType === 'line' && (
            chartData[layout.i] && <Chart
              chartConfig={layout.chartConfig}
              data={chartData[layout.i]}
                                   />
          )}
          <button
            className='react-resizable-handle remove'
            style={{ ...adjustButtonStyle, right: '95px', left: 'auto' }}
            onClick={() => adjustStartDate(layout.i, 1)}
          >+
          </button>
          <button
            className='react-resizable-handle remove'
            style={{ ...adjustButtonStyle, right: '65px', left: 'auto' }}
            onClick={() => adjustStartDate(layout.i, -1)}
          >-
          </button>
          <button
            className='react-resizable-handle remove'
            style={settingsButtonStyle}
            onClick={() => openChartModal(layout.chartConfig)}
          >
            <SettingsIcon style={{ color: 'rgb(117, 117, 117)' }} />
          </button>
          <button
            className='react-resizable-handle remove'
            style={removeButtonStyle}
            onClick={() => handleRemove(layout.i)}
          >
            <CloseIcon style={{ color: 'rgb(117, 117, 117)' }} />
          </button>
        </div>
      ))}
    </ResponsiveReactGridLayout>
  );
};

export default ChartsGrid;

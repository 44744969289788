// Assets.js
import React, { useEffect } from 'react';
import { Box, Paper } from '@mui/material';
import assetsStore from '../../store/assetsStore';
import AssetForm from './AssetForm';
import AssetList from './AssetList';
import { useSnackbar } from 'notistack';
const Assets = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { assets, fetchAssets, addAsset, removeAsset } = assetsStore(state => ({
    assets: state.assets,
    fetchAssets: state.fetchAssets,
    addAsset: state.addAsset,
    removeAsset: state.removeAsset
  }));

  useEffect(() => {
    fetchAssets();
  }, [fetchAssets]);

  const handleDeleteAsset = async (timestamp, Name) => {
    try {
      await removeAsset(timestamp, Name);
      enqueueSnackbar('Asset deleted successfully', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Failed to delete asset', { variant: 'error' });
    }
  };

  const handleCreateAsset = async (Name, Samples) => {
    try {
      await addAsset({ Name, Samples });
      enqueueSnackbar('Asset set successfully', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Failed to set asset, ', { variant: 'error' });
    }
  };

  return (
    <Box p={3}>
      <Paper style={{ padding: '10px', marginBottom: '10px', backgroundColor: '#f5f5f5' }}>
        <AssetForm onCreateAsset={handleCreateAsset} />
      </Paper>
      <AssetList assets={assets} onDeleteAsset={handleDeleteAsset} />
    </Box>
  );
};

export default Assets;

import React, { useState, useEffect } from 'react';
import { Box, TextField, FormControl, InputLabel, Select, MenuItem, Grid } from '@mui/material';

export default function FiltersForm ({ onFiltersChange }) {
  const [eventType, setEventType] = useState('');
  const [maxValue, setMaxValue] = useState('');
  const [minValue, setMinValue] = useState('');

  useEffect(() => {
    onFiltersChange({
      eventType,
      maxValue: maxValue || undefined,
      minValue: minValue || undefined
    });
  }, [eventType, maxValue, minValue, onFiltersChange]);

  return (
    <Box>

      <Grid container spacing={2} alignItems='center'>
        <Grid item>
          <FormControl size='small' sx={{ minWidth: 120 }}>
            <InputLabel>Event Type</InputLabel>
            <Select
              value={eventType}
              label='Event Type'
              onChange={(e) => setEventType(e.target.value)}
            >
              <MenuItem value=''>
                <em>Show All</em>
              </MenuItem>
              <MenuItem value='Gray'>Gray</MenuItem>
              <MenuItem value='Red'>Red</MenuItem>
              <MenuItem value='Yellow'>Yellow</MenuItem>
              <MenuItem value='Green'>Green</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <TextField
            label='Min Value'
            type='number'
            size='small'
            value={minValue}
            onChange={(e) => setMinValue(e.target.value)}
            sx={{ width: '100px' }}
          />
        </Grid>
        <Grid item>
          <TextField
            label='Max Value'
            type='number'
            size='small'
            value={maxValue}
            onChange={(e) => setMaxValue(e.target.value)}
            sx={{ width: '100px' }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

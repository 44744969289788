import React from 'react';
import ChartsGrid from './ChartsGrid';

import layoutsStore from '../../store/layoutsStore';

const Layouts = (props) => {
  const { getLayouts, setLayouts } = layoutsStore(state => ({ getLayouts: state.getLayouts, setLayouts: state.setLayouts }));
  const layouts = getLayouts();
  return (
    <>
      {layouts.length === 0
        ? (
          <div className='no-widgets-message'>
            <p>There aren't any widgets added yet.</p>
          </div>
          )
        : (
          <ChartsGrid
            onLayoutRemove={(layouts_) => setLayouts(layouts_.lg)}
            onLayoutChange={(layout, layouts_) => {
              if (!layouts_) {
                return;
              }
              const newLayouts = mergeObjectArrays(layouts_.lg, layouts);
              setLayouts(newLayouts);
            }}
            layouts={{ lg: layouts }}
            {...props}
          />
          )}
    </>
  );
};

function mergeObjectArrays (array1, array2) {
  if (!Array.isArray(array1) || !Array.isArray(array2)) {
    throw new Error('Both arguments must be arrays');
  }

  return array1.map((obj, index) => {
    if (array2[index]) {
      return { ...obj, ...array2[index] };
    }
    return obj;
  });
}

export default Layouts;

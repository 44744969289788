import React from 'react';
import './DataTable.css'; // Importing CSS file for styling
const DataTable = ({ data }) => {
  return (
    <div className='dataTableContainer'>
      <table className='dataTable'>
        <thead>
          <tr>
            <th>Timestamp</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td>{new Date(item.ts).toLocaleString()}</td>
              <td>{item.val}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DataTable;

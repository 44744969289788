import React from 'react';
import { Grid, Card, CardHeader, CardContent, IconButton, Divider, Box, Typography, Tooltip, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const AlarmCards = ({ alarms, handleDeleteAlarm }) => {
  const theme = useTheme();

  // Function to get soft color based on alarm type
  const getSoftColorForType = (type) => {
    switch (type) {
      case 'Green':
        return theme.palette.success.light; // Soft green
      case 'Yellow':
        return theme.palette.warning.light; // Soft yellow
      case 'Red':
        return theme.palette.error.light; // Soft red
      default:
        return theme.palette.grey[200]; // Default color
    }
  };

  return (
    <Grid container spacing={2}>
      {alarms.map((alarm, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Card>
            <CardHeader
              action={
                <Tooltip title='Delete Alarm'>
                  <IconButton aria-label='close' onClick={() => handleDeleteAlarm(alarm.timestamp, alarm.SampleName)}>
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              }
              title={alarm.Name}
              subheader={`Tag: ${alarm.SampleName}`}
              style={{ backgroundColor: getSoftColorForType(alarm.type) }} // Set background color based on alarm type
            />
            <Divider />
            <CardContent>
              <Box>
                <Typography variant='body2' color='textSecondary'>Max Value: {alarm.maxValue}</Typography>
                <Typography variant='body2' color='textSecondary'>Min Value: {alarm.minValue}</Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default AlarmCards;

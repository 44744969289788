import React, { useState } from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, TableSortLabel } from '@mui/material';

function EventsTable ({ events, page, rowsPerPage, handleChangePage, handleChangeRowsPerPage, count }) {
  const [orderDirection, setOrderDirection] = useState('desc');
  const [orderBy, setOrderBy] = useState('ts');

  const handleRequestSort = (property) => () => {
    const isAsc = orderBy === property && orderDirection === 'asc';
    setOrderDirection(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortEvents = (array) => {
    return array.sort((a, b) => {
      let compA = a[orderBy];
      let compB = b[orderBy];

      if (orderBy === 'ts') {
        compA = new Date(a.ts);
        compB = new Date(b.ts);
      }

      if (compA < compB) {
        return orderDirection === 'desc' ? 1 : -1;
      }
      if (compA > compB) {
        return orderDirection === 'desc' ? -1 : 1;
      }
      return 0;
    });
  };

  const getRowColor = (eventType) => {
    switch (eventType) {
      case 'Red':
        return '#FFCDD2';
      case 'Gray':
        return '#E0E0E0';
      case 'Yellow':
        return '#FFF9C4';
      case 'Green':
        return '#C8E6C9';
      default:
        return '';
    }
  };

  const sortedEvents = sortEvents([...events]);

  return (
    <Paper>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'ts'}
                  direction={orderBy === 'ts' ? orderDirection : 'asc'}
                  onClick={handleRequestSort('ts')}
                >
                  Time
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'SampleName'}
                  direction={orderBy === 'SampleName' ? orderDirection : 'asc'}
                  onClick={handleRequestSort('SampleName')}
                >
                  Sample Name
                </TableSortLabel>
              </TableCell>
              <TableCell>Value</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'MAC'}
                  direction={orderBy === 'MAC' ? orderDirection : 'asc'}
                  onClick={handleRequestSort('MAC')}
                >
                  MAC
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'Name'}
                  direction={orderBy === 'Name' ? orderDirection : 'asc'}
                  onClick={handleRequestSort('Name')}
                >
                  Name
                </TableSortLabel>
              </TableCell>
              <TableCell>Low Limit</TableCell>
              <TableCell>High Limit</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedEvents.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((event, index) => (
              <TableRow key={index} style={{ backgroundColor: getRowColor(event.type) }}>
                <TableCell>{new Date(event.ts).toLocaleString()}</TableCell>
                <TableCell>{event.SampleName}</TableCell>
                <TableCell>{event.value}</TableCell>
                <TableCell>{event.type}</TableCell>
                <TableCell>{event.MAC}</TableCell>
                <TableCell>{event.Name}</TableCell>
                <TableCell>{event.lowLimit?.toString()}</TableCell>
                <TableCell>{event.highLimit?.toString()}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component='div'
        count={count}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[15, 25, 50, 100]}
        labelRowsPerPage='Items per page:'
      />
    </Paper>
  );
}

export default EventsTable;

import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button } from '@mui/material';

const UpdateUserDialog = ({ open, onClose, user, onUserUpdate }) => {
  const [updatedUser, setUpdatedUser] = useState({ username: '', email: '', permissions: [] });

  useEffect(() => {
    if (user) {
      setUpdatedUser({ ...user });
    }
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdatedUser({ ...updatedUser, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onUserUpdate(updatedUser);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Update User</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit} noValidate>
          <TextField
            margin='dense'
            id='username'
            label='Username'
            type='text'
            fullWidth
            variant='outlined'
            name='username'
            value={updatedUser.username}
            onChange={handleChange}
          />
          <TextField
            margin='dense'
            id='email'
            label='Email'
            type='email'
            fullWidth
            variant='outlined'
            name='email'
            value={updatedUser.email}
            onChange={handleChange}
          />
          {/* Add more fields here as needed */}
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit}>Update</Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateUserDialog;

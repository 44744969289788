import React from 'react';
import { Checkbox, FormControlLabel, FormGroup, Grid } from '@mui/material';
import { updateUserPermissions } from '../../apiservice/apiService.js';

const UserPermissions = ({ user, permissionsList, onPermissionChange }) => {
  const handlePermissionChange = async (permission, isChecked) => {
    console.log(`Updating permission ${permission} for user ${user.id} to ${isChecked}`);
    try {
      await updateUserPermissions(user.id, permission, isChecked);
      onPermissionChange();
    } catch (error) {
      console.error('Failed to update permissions:', error);
    }
  };

  return (
    <FormGroup>
      <Grid container spacing={2}>
        {permissionsList.map((permission) => (
          <Grid item key={permission}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={user.permissions && user.permissions.includes(permission)}
                  onChange={(e) => handlePermissionChange(permission, e.target.checked)}
                  name={permission}
                />
              }
              label={permission}
            />
          </Grid>
        ))}
      </Grid>
    </FormGroup>
  );
};

export default UserPermissions;

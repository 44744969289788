// AssetList.js
import React from 'react';
import { Grid, Card, CardHeader, IconButton, Typography, CardContent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const AssetList = ({ assets, onDeleteAsset }) => {
  return (
    <Grid container spacing={2}>
      {assets.map((asset, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Card>
            <CardHeader
              action={
                <IconButton aria-label='close' onClick={() => onDeleteAsset(asset.timestamp, asset.Name)}>
                  <CloseIcon />
                </IconButton>
              }
              title={asset.Name}
              subheader='Tags:'
            />
            <CardContent>
              <Typography variant='body2'>
                {asset.Samples.map(s => s.SampleName).join(', ')}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default AssetList;

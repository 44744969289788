import React from 'react';
import { Avatar, Drawer, List, ListItem, ListItemIcon, ListItemText, IconButton } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';

import { useStore } from '../../store/store';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DevicesIcon from '@mui/icons-material/Devices';
import EventIcon from '@mui/icons-material/Event';
import AlarmIcon from '@mui/icons-material/Alarm';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import MenuIcon from '@mui/icons-material/Menu';
import useMediaQuery from '@mui/material/useMediaQuery';

export const DrawerComponent = () => {
  const location = useLocation();

  const { drawerOpen, toggleDrawer } = useStore((state) => ({
    drawerOpen: state.drawerOpen,
    toggleDrawer: state.toggleDrawer
  }));

  const isMobile = useMediaQuery('(max-width:600px)');

  const menuItems = [
    { text: 'Dashboard', path: '/', icon: <DashboardIcon /> },
    { text: 'Devices', path: '/devices', icon: <DevicesIcon /> },
    { text: 'Assets', path: '/assets', icon: <CollectionsBookmarkIcon /> },
    { text: 'Alarms', path: '/alarms', icon: <AlarmIcon /> },
    { text: 'Events', path: '/events', icon: <EventIcon /> },
    { text: 'Admin Panel', path: '/admin', icon: <AdminPanelSettingsIcon /> }
  ];

  return (
    <>
      {isMobile && (
        <IconButton
          color='inherit'
          aria-label='open drawer'
          edge='start'
          onClick={toggleDrawer}
          sx={{ mr: 2 }}
        >
          <MenuIcon />
        </IconButton>
      )}

      <Drawer
        variant={isMobile ? 'temporary' : 'permanent'}
        open={isMobile ? drawerOpen : true}
        onClose={toggleDrawer}
      >
        <Link to='/' onClick={isMobile ? toggleDrawer : undefined}>
          <Avatar
            src='/asenconnect.png'
            alt='Logo'
            variant='square'
            style={{ margin: '0 auto', width: '170px', height: 'auto', padding: '5px' }}
          />
        </Link>
        <List style={{ width: '200px' }}>
          {menuItems.map((item, index) => {
            const isActive = location.pathname === item.path;

            return (
              <ListItem
                button
                key={index}
                component={Link}
                to={item.path}
                onClick={isMobile ? toggleDrawer : undefined}
                style={{ backgroundColor: isActive ? '#e0e0e0' : 'transparent' }}
              >
                <ListItemIcon>
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItem>
            );
          })}
        </List>
      </Drawer>
    </>
  );
};

export default DrawerComponent;

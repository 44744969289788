import React from 'react';
import GaugeComponent from 'react-gauge-component';

const CustomGaugeChart = ({ min, max, currentValue }) => {
  // Calculate the percentage for the gauge
  const percentage = (currentValue * 100 / (max - min));

  return (
    <GaugeComponent
      arc={{
        subArcs: [
          {
            limit: 20,
            color: '#EA4228',
            showTick: true
          },
          {
            limit: 40,
            color: '#F58B19',
            showTick: true
          },
          {
            limit: 60,
            color: '#F5CD19',
            showTick: true
          },
          {
            limit: 100,
            color: '#5BE12C',
            showTick: true
          }
        ]
      }}
      value={percentage}
    />
  );
};

export default CustomGaugeChart;

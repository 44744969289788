import React from 'react';
import {
  Typography,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Container,
  useTheme
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'; // Import an icon

const Product = () => {
  const theme = useTheme();

  const cardStyle = {
    marginBottom: theme.spacing(4),
    '&:last-child': {
      marginBottom: 0
    },
    boxShadow: theme.shadows[3] // This adds a consistent shadow based on theme
  };

  const listItemStyle = {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.palette.divider}` // adds a light border between list items
    }
  };

  return (
    <Container maxWidth='lg'>
      <Card elevation={0} square style={{ backgroundColor: 'transparent' }}>
        <CardContent align='center' sx={{ py: theme.spacing(6) }}>
          <Typography variant='h3' component='h1' gutterBottom>
            ASENCO IOT PLATFORM
          </Typography>
          <Typography variant='h5' color='textSecondary' gutterBottom>
            Your Guide in the Digital Transformation Journey
          </Typography>
        </CardContent>
      </Card>

      <Card sx={cardStyle}>
        <CardContent>
          <Typography variant='h6' gutterBottom>
            What is ASENCO IOT Platform?
          </Typography>
          <Typography>
            The ASENCO IOT Platform enables fast, scalable, and secure connection of objects to the internet using multiple protocols and encryption.
          </Typography>
        </CardContent>
      </Card>

      <Card sx={cardStyle}>
        <CardContent>
          <Typography variant='h6' gutterBottom>
            Why Should We Use ASENCO IOT Solutions?
          </Typography>
          <List>
            {[
              'Seamless Integration with ease.',
              'All-in-one IoT Platform for various needs.',
              'Easily connect any device.',
              'Understand your device data with advanced interpretation tools.',
              'Network and Data Security are prioritized for information integrity.'
            ].map((text, index) => (
              <ListItem key={index} style={listItemStyle}>
                <ListItemIcon>
                  <CheckCircleOutlineIcon /> {/* This is the icon */}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItem>
            ))}
          </List>
        </CardContent>
      </Card>
    </Container>
  );
};

export default Product;

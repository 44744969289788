const apiConfig = {
  BASE_URL: 'https://ip59nu135m.execute-api.us-east-1.amazonaws.com/prod',
  ENDPOINTS: {
    GET_TAGS: '/getTags',
    S3_DATA: '/s3data',
    GET_ALARMS: '/getLogs',
    GET_USER_DATA: '/read',
    UPDATE_USER_DATA: '/update',
    GET_SAMPLES: '/getSamples',
    CREATE_ALARM: '/alarms/create',
    READ_ALARMS: '/alarms/read',
    UPDATE_ALARM: '/alarms/update',
    DELETE_ALARM: '/alarms/delete',
    Create_ASSET: '/assets/create',
    READ_ASSETS: '/assets/read',
    UPDATE_ASSET: '/assets/update',
    DELETE_ASSET: '/assets/delete',
    READ_EVENTS: '/events/read',
    LIST_USERS: '/user/list',
    CREATE_USER: '/user/create',
    NOTIFICATOINS: '/notifications',
    UPDATE_NOTIFICATIONS: '/notifications/update',
    REGISTER: '/register',
    LOGIN: '/login',
    UPDATE_USER_PERMISSIONS: '/user/updatePermissions'
  },
  HEADERS: {
    'Content-Type': 'application/json'
  },
  PARAMS: {
    // Common query parameters can be defined here
  }
};

export default apiConfig;

import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { Notifications, updateNotifications } from '../apiservice/apiService';

const useNotificationStore = create(persist(
  (set, get) => ({
    notifications: [],

    addNotification: notification =>
      set(state => {
        const exists = state.notifications.some(notif => notif.ts === notification.ts);
        if (!exists) {
          return { notifications: [...state.notifications, { ...notification, seen: false }] };
        }
        return state;
      }),

    removeNotification: ts =>
      set(state => ({
        notifications: state.notifications.filter(notif => notif.ts !== ts)
      })),

    fetchAndUpdateNotifications: async () => {
      try {
        const response = await Notifications();
        const redEvents = response.results.filter(event => event.type === 'Red');
        redEvents.forEach(event => {
          get().addNotification({
            ts: event.ts,
            SampleName: event.SampleName,
            value: event.value,
            seen: false
          });
        });
      } catch (error) {
        console.error('Failed to fetch events:', error);
      }
    },

    markNotificationsSeenLocally: () =>
      set(state => ({
        notifications: state.notifications.map(notification => ({
          ...notification,
          seen: true
        }))
      })),

    markNotificationsSeen: async () => {
      const tsArray = get().notifications.map(notification => notification.ts);
      if (tsArray.length > 0) {
        try {
          await updateNotifications(tsArray);
        } catch (error) {
          console.error('Failed to mark notifications as seen:', error);
        }
      }
    }
  }),
  {
    name: 'notification-store',
    getStorage: () => window.localStorage
  }
));

export default useNotificationStore;

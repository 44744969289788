import React from 'react';
import { Typography, TextField, Button, Box } from '@mui/material';

const UserProfileForm = ({ formData, handleChange, handleSubmit, error }) => {
  return (
    <div>
      <TextField
        label='Username'
        variant='outlined'
        name='username'
        value={formData.username}
        onChange={handleChange}
        fullWidth
        margin='normal'
        required
      />

      <TextField
        label='Email'
        variant='outlined'
        name='email'
        value={formData.email}
        onChange={handleChange}
        fullWidth
        margin='normal'
        required
        type='email'
        disabled
      />

      <TextField
        label='Company Name'
        variant='outlined'
        name='companyName'
        value={formData.companyName}
        onChange={handleChange}
        fullWidth
        margin='normal'
      />

      <TextField
        label='Company Logo URL'
        variant='outlined'
        name='companyLogoUrl'
        value={formData.companyLogoUrl}
        onChange={handleChange}
        fullWidth
        margin='normal'
        type='url'
      />

      <TextField
        label='Secomea JSON'
        variant='outlined'
        name='secomeaJSON'
        value={formData.secomeaJSON}
        onChange={handleChange}
        fullWidth
        margin='normal'
        multiline
        rows={4}
        placeholder='Enter JSON data here'
      />

      <Box my={2} display='flex' justifyContent='center'>
        <Button
          type='submit'
          variant='contained'
          color='primary'
          onClick={handleSubmit}
        >
          Update Details
        </Button>
      </Box>

      {error && (
        <Typography variant='body1' color='error'>
          {error}
        </Typography>
      )}
    </div>
  );
};

export default UserProfileForm;

import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { useStore } from './store/store';
import { CssBaseline } from '@mui/material';
import Navbar from './components/navbar/Navbar';
import DrawerComponent from './components/drawer/DrawerComponent';
import MainContent from './components/MainContent';
import MainPage from './publicPage/MainPage';
import { SnackbarProvider } from 'notistack';

const App = () => {
  const { isLoggedIn } = useStore((state) => ({
    isLoggedIn: state.isLoggedIn
  }));

  return (
    <Router>
      <CssBaseline />
      <div className='App'>
        <SnackbarProvider maxSnack={3}>
          {isLoggedIn
            ? (
              <div
                className={`${isLoggedIn ? 'logged-in' : ''}`}
                style={{ backgroundColor: '#F0F0F0' }}
              >
                <Navbar className='Navbar' />
                <DrawerComponent className='DrawerComponent' />
                <MainContent className='MainContent' />
              </div>
              )
            : (
              <MainPage className='MainPage' />
              )}
        </SnackbarProvider>
      </div>
    </Router>
  );
};

export default App;

import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField } from '@mui/material';
import { register } from '../../apiservice/apiService.js';
import { useSnackbar } from 'notistack';

const AddUserDialog = ({ open, onClose, onUserAdded }) => {
  const [newUser, setNewUser] = useState({ password: '', email: '', role: 'user' });
  const { enqueueSnackbar } = useSnackbar(); // Using useSnackbar hook to show notifications

  const handleAdd = async () => {
    try {
      console.log('Adding user:', newUser);
      await register(newUser.email, newUser.password);
      // On successful user addition
      enqueueSnackbar('User added successfully', { variant: 'success' });
      onUserAdded(); // Notify the parent component that a user was added
      onClose(); // Close the modal on success
    } catch (error) {
      // On failure
      console.error('Error adding user:', error);
      enqueueSnackbar('Failed to add user', { variant: 'error' });
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add New User</DialogTitle>
      <DialogContent>
        <TextField
          margin='dense'
          id='email'
          label='Email Address'
          type='email'
          fullWidth
          variant='outlined'
          value={newUser.email}
          onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
        />
        <TextField
          autoFocus
          margin='dense'
          id='password'
          label='Password'
          type='text'
          fullWidth
          variant='outlined'
          value={newUser.password}
          onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleAdd}>Add</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddUserDialog;

import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import useAlarmsStore from '../../store/alarmsStore';
import { useSnackbar } from 'notistack';
import AlarmCards from './AlarmCards';
import AlarmForm from './AlarmForm';

const Alarms = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { alarms, fetchAlarms, addAlarm, removeAlarm } = useAlarmsStore(state => ({
    alarms: state.alarms,
    fetchAlarms: state.fetchAlarms,
    addAlarm: state.addAlarm,
    removeAlarm: state.removeAlarm
  }));

  useEffect(() => {
    fetchAlarms();
  }, [fetchAlarms]);

  const handleSetAlarm = async (newAlarmData) => {
    try {
      await addAlarm(newAlarmData);
      enqueueSnackbar('Alarm set successfully', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Failed to set alarm', { variant: 'error' });
    }
  };

  const handleDeleteAlarm = async (timestamp, SampleName) => {
    try {
      await removeAlarm(timestamp, SampleName);
      enqueueSnackbar('Alarm deleted successfully', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Failed to delete alarm', { variant: 'error' });
    }
  };

  return (
    <Box p={3}>
      <AlarmForm onSetAlarm={handleSetAlarm} />
      <AlarmCards alarms={alarms} handleDeleteAlarm={handleDeleteAlarm} />
    </Box>
  );
};

export default Alarms;

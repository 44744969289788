import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { readAlarms, createAlarm, updateAlarm, deleteAlarm } from '../apiservice/apiService';

const useAlarmsStore = create(persist(
  (set, get) => ({
    alarms: [],
    fetchAlarms: async () => {
      const alarmData = await readAlarms();
      set({ alarms: alarmData?.results });
    },
    addAlarm: async (newAlarmData) => {
      const result = await createAlarm(newAlarmData);
      set((state) => ({ alarms: [...state.alarms, result.alarm] }));
    },
    updateAlarm: async (alarmId, updatedData) => {
      const updatedAlarm = await updateAlarm(alarmId, updatedData);
      set((state) => ({
        alarms: state.alarms.map((alarm) => (alarm.id === alarmId ? updatedAlarm : alarm))
      }));
    },
    removeAlarm: async (timestamp, SampleName) => {
      await deleteAlarm(timestamp, SampleName);
      set((state) => ({
        alarms: state.alarms.filter((alarm) => alarm.timestamp !== timestamp)
      }));
    }
  }),
  {
    name: 'alarms-store',
    getStorage: () => window.localStorage
  }
));

export default useAlarmsStore;
